import React from 'react';
import { Suspense } from 'react';
import LazyImage from '../lazyImage';
import FreeConsultationBtn from '../FreeConsultationBtn';


const Typewriter = React.lazy(() => import('typewriter-effect'));


function HeroSection() {
    return (
        // <Suspense fallback={<CircleLoader isVisible={true}/>}>
        <div className='header w-full min-h-[80svh] lg:min-h-[100vh] flex justify-center items-center relative overflow-hidden '>
            <div className='header-content hidden lg:flex flex-row justify-between absolute z-10 container mx-auto'>
                <div className='header-text flex flex-col gap-3 items-start text-white justify-center px-5 lg:w-[70%] xl:w-[80%]'>
                    <div className="typing-wrapper">
                    <Suspense fallback={<span>Loading animation...</span>}>
                        <Typewriter
                            options={{
                                strings: ['Brightly', 'Budget Friendly' , 'Beverly'],
                                autoStart: true,
                                loop: true,
                            }}
                            className="typing-demo lg:text-[58px] xl:text-[78px] font-bold text-[#D9D9D9] text-start"
                        />
                        </Suspense>
                    </div>
                    <p className='text-white lg:text-[21px] text-[16px] xl:text-[24px] font-[100] leading-[36px] w-[85%]'>
                        Your Go-To Marketing Solutions<br/>
                        Tailored for Every Budget<br />
                        Delivering Quality Without Compromise
                    </p>
                    <FreeConsultationBtn />
                </div>
                <LazyImage src='./heroimage.png' alt='HeroImage' className='lg:w-[50%] ' />
            </div>
            <div className=' flex flex-col items-center gap-3 lg:hidden  mb-10'>
                <div className="text-[36px] ">
                    <Typewriter
                        options={{
                            strings: ['Brightly', 'Budget Friendly' , 'Beverly'],
                            autoStart: true,
                            loop: true,
                        }}
                        className="typing-demo text-[36px] font-bold text-[#D9D9D9] text-start"
                    />
                </div>

                <LazyImage src='./heroimage.png' alt='HeroImage' clss='w-[300px] pl-5' />

                <p className='text-white text-[16px] text-center font-[300] leading-[23px] w-[100%]'>
                Your Go-To Marketing Solutions<br/>
                        Tailored for Every Budget<br />
                        Delivering Quality Without Compromise
                </p>

                <FreeConsultationBtn />
            </div>
            <div className='header-image'></div>
        </div>
        // </Suspense>
    )
}

export default HeroSection 