import React from 'react'
import SeoHeroSection from './SeoHeroSection'
import SeoSectionTwo from './SeoSectionTwo'
import SeoSectionThree from './SeoSectionThree'
import SeoSectionFour from './SeoSectionFour'
import SeoSectionFive from './SeoSectionFive'
import SeoEndSection from './SeoEndSection'
import {Helmet} from "react-helmet";

function SeoPage() {
  return (
    <div className='text-white mb-[100px] overflow-hidden'>
      <Helmet>
    <title>SEO Services in Canada | Improve Your Google Ranking & Visibility</title>
    <meta name="description" content="Boost your website’s visibility with expert SEO services. Beverly Digital offers local SEO, technical SEO, and content optimization to help your business grow." />
    <meta name="keywords" content="SEO services Canada, Google ranking improvement, Local SEO Toronto, Search engine optimization, Technical SEO agency, SEO for small businesses, Keyword research experts, On-page and off-page SEO, Website optimization, Organic traffic growth" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
</Helmet>
      <SeoHeroSection />
      <SeoSectionTwo />
      <div className='relative seo-sec2-background-2 ' >
        <div className='sticky top-0'>
      <SeoSectionThree />
      <SeoSectionFour />
      <SeoSectionFive />
      </div></div>
      <SeoEndSection />
    </div>
  )
}

export default SeoPage
