import React from 'react'
import WebHeroSection from './WebHeroSection'
import WebSectionTwo from './WebSectionTwo'
import WebSectionThree from './WebSectionThree'
import WebSectionFour from './WebSectionFour'
import WebEndSection from './WebEndSection'
import {Helmet} from "react-helmet";


function WebDesignPage() {
  return (
    <div className='text-white mb-[100px] overflow-hidden'>
      <Helmet>
    <title>Web Design Services Canada | Custom Websites for Business Growth</title>
    <meta name="description" content="Need a professional website? Beverly Digital specializes in responsive, SEO-friendly web design for small businesses, startups, and e-commerce brands in Canada." />
    <meta name="keywords" content="Web design Canada, Custom website development, Small business website design, Toronto web agency, Responsive web design, UI/UX design services, SEO-friendly websites, WordPress website development, Business website solutions, Website redesign services" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
</Helmet>
      <WebHeroSection />

      <WebSectionTwo />
      <div className='relative seo-sec2-background-2 ' >
        <div className='sticky top-0'>
    <WebSectionThree />
    <WebSectionFour />

      </div></div>
      <WebEndSection />
    </div>
  )
}

export default WebDesignPage
