import React from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import profile1 from './Team/Behroyan.png';
import profile2 from './Team/Soltani.png';
import profile3 from './Team/Namirani.png';
import profile4 from './Team/Heidari.png';
import profile5 from './Team/Amiri.png';
import profile6 from './Team/Valy.png';
import profile7 from './Team/Nikzad.png';
import profile8 from './Team/Vafaei.png';
import profile9 from './Team/Mohammadi.png';
import profile10 from './Team/Aghili.png';
import {Helmet} from "react-helmet";

import LazyImage from './lazyImage';
const About = () => {
  const items = [
    { imageUrl: profile1, role: 'CEO & Co-Founder', name: 'Mohammad Behroyan' },
    { imageUrl: profile2, role: 'CTO & Co-Founder', name: 'Tom Soltani' },
    { imageUrl: profile3, role: 'Team Lead', name: 'Sam Namirani' },
    { imageUrl: profile4, role: 'CMO & ADS Specialist', name: 'Max Heidari' },
    { imageUrl: profile5, role: 'Brand Advisor', name: 'Ella Amiri' },
    { imageUrl: profile8, role: 'Customer Consultant', name: 'Benjamin Vafaei' },
    { imageUrl: profile7, role: 'Developer', name: 'Andy Nikzad' },
    { imageUrl: profile6, role: 'Animation Advisor', name: 'Anjela Valy' },
    { imageUrl: profile9, role: 'Graphist', name: 'Britney Mohammadi' },
    { imageUrl: profile10, role: 'International Investor', name: 'Kamyab Aghili' }
    // { imageUrl: profile10, role: 'Brand Manager', name: 'Maleki' }
  ];

  const Texts = [" We only engage if we can add value: If a company already has a solid marketing strategy or has reached its full potential, we won't engage. We prioritize impact over opportunity."
    , "We operate with fairness and integrity: Our business practices are built on ethical foundations, prioritizing fairness to all stakeholders and meeting our high moral standards – beyond what's legally required."
    , " We deliver exceptional value: Our products and services are designed to be beneficial, useful, effective, and exceed client expectations."]

  return (

    <div role="presentation">
      <Helmet><title>About Beverly Digital | Your Partner in Branding & Digital Growth</title>
<meta name="description" content="Learn more about Beverly Digital, a creative marketing agency helping small businesses thrive with branding, SEO, web design, and innovative digital strategies." />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta name="keywords" content="About Beverly Digital, Digital marketing agency Canada, Branding experts Toronto, Small business growth solutions, SEO and web design specialists, Creative marketing agency, Our mission in branding, Digital transformation services, Meet our marketing team, Business branding in Canada" />
</Helmet>


      <Box sx={{
        backgroundImage: "url('aboutBack.png')",
        backgroundSize: { lg: '80%', md: '100%' },
        backgroundRepeat: 'no-repeat',
        opacity: '1',
        backgroundPosition: { lg: '700px -600px', md: '90px 500px' },
        overflow: 'hidden',
        mx: { md: '40px', xs: '20px', lg: 0 }
        // zIndex:'-1'
      }}>
        <Grid container spacing={2} sx={{
          overflow: 'hidden',
          height: 'auto',
          alignItems: 'center',
          padding: '10px',
          display: 'flex',
          mt: 20,
          flexDirection: { md: 'row', xs: 'column-reverse' },
          // alignItems:'center'
        }}>

          <Grid item xs={12} lg={6} sx={{
            textAlign: 'left',
            height: 'auto',
            display: 'flex',
            flexDirection: { lg: 'column', md: 'column', xs: 'column-reverse' },
            alignItems: 'center',
            overflow: 'hidden'
          }}>
            <Box>
              <Typography variant="h2" gutterBottom sx={{
                fontFamily: "Blinker",
                fontWeight: '800',
                fontSize: { xs: '40px', md: '64px', lg: '72px' },
                my: 5,
                // Responsive font size
                lineHeight: '40px'
              }}>
                Our Story
              </Typography>
              <Typography variant="body1" sx={{
                fontFamily: "Blinker",
                fontWeight: '400',
                fontSize: { xs: '15px', md: '24px' },
                width: { xs: '100%', md: '540px' }, // Responsive width
                lineHeight: '36px',
                mb: 4
              }}>
                <strong>Beverly</strong> was founded by three friends from diverse backgrounds united in our passion for digital marketing. Prior to starting Beverly, we learned first-hand, based on our <strong>50 years of combined business experience</strong>, that building a business that offers an exceptional product or service isn't enough to succeed. You must also effectively communicate what you offer to your potential clients. This insight led us to establish Beverly with a simple yet ambitious mission: <strong>“to help start-ups reach their full potential."</strong>
              </Typography>
              <Typography variant="h5" gutterBottom sx={{
                fontFamily: "Blinker",
                fontWeight: '400',
                fontSize: { xs: '15px', md: '24px' },
                width: { xs: '100%', md: '540px' }, // Responsive width
                lineHeight: '36px'
              }}>
                Since our inception, we have been guided by <strong>three core principles</strong> that drive us to fulfill our mission:
              </Typography>


            </Box>
          </Grid>
          <Grid item xs={12} lg={6} sx={{
            position: { md: 'relative', xs: 'static' },
            // overflow: 'hidden',
            height: 'auto',
            // zIndex: -1,
            // mt: { lg: -75 }
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden'
          }}>
            <LazyImage src='aboutImage.png' alt="About" sx={{

              display: 'flex',
              // right: {md:0},
              // top:'40px',
              width: '100%',
              height: '75%',
              textAlign: 'center',
              justifyContent: 'center',
              justifyItems: 'center'



            }} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={12} sx={{
            mt: { lg: 10, md: 90, xs: 60 },
            display: 'flex',
            flexDirection: { md: 'column', xs: 'row' },
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
            ml: { md: 0 }
          }}>
            <Grid container spacing={4} sx={{
              maxWidth: '1200px',
              width: '100%',
              display: 'flex',
              flexDirection: { md: 'row', xs: 'column' },
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              {Texts.map(x => (
                <Grid item lg={4} md={6} xs={12}>
                  <Box sx={{
                    width: { md: '343px', xs: '260px' }
                    , height: { md: '350px', xs: '270px' },

                    background: 'linear-gradient(35deg, rgba(2,0,36,0) 2%, rgba(187,198,243,0.21052170868347342) 100%)',
                    borderRadius: '30px',
                    border: '1px solid #10F9B1',
                    alignContent: 'center'
                  }}>
                    <Typography sx={{
                      p: 4,
                      fontSize: { md: '20px', xs: '12px' },
                      fontFamily: 'blinker',
                      lineHeight: '38px',
                      fontWeight: '400'

                    }}>{x}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{

          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          alignContent: 'center',
          // mt: { lg: 30, md: 2, sm: 25 },
          mb: 4,
          flexWrap: 'nowrap',
        }}>
          <Grid item xs={12} sx={{
            mb: 10
          }}>
            <Typography sx={{
              mt: { md: '150px', xs: '100px' },
              fontFamily: "Blinker",
              fontWeight: '800',
              fontSize: { xs: '40px', md: '64px', lg: '72px' }, // Responsive font size
              textAlign: 'center',
              lineHeight: '91%'
            }}>
              Our Team<br />
              We are the remote-natives
            </Typography>
          </Grid>
          <Grid container sx={{
            display: 'flex',
            maxWidth: {xl : '900px', lg  : '900px', md : '650px',  xs  :'400px'},
            justifyContent: 'center',
            textAlign: 'center',
            height: '80%',
            mt: { lg: 3, md: 12, sm: 20, xs: 10 }
          }}>
            {items.map((item, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index} sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: 3,
                height: 'autopx',
              }}>
                <Box sx={{
                  boxShadow: '1px 1px 5px rgba(0,0,0,0.3)',
                  backgroundColor: '#344051',
                  borderRadius: '10px',
                  width: '260px',
                  padding: '24px',
                  justifyItems: 'center',
                  textAlign: 'center'
                }}>
                  <Box
                    sx={{
                      width: '156px',
                      height: '156px',
                      borderRadius: '20%',
                      overflow: 'hidden',
                    }}
                  >
                  <LazyImage src={item.imageUrl} alt={`Profile ${index + 1}`} sx={{
                    width: '156px',
                    height: '156px',
                    borderRadius: '50% !important',
                  }}
                  /></Box>
                  <Typography sx={{
                    mt: 2,
                    fontFamily: "Blinker",
                    fontWeight: '600',
                    fontSize: { xs: '15px', md: '24px' },
                  }}>
                    {item.name}
                  </Typography>
                  <Typography sx={{
                    fontFamily: "Blinker",
                    fontWeight: '400',
                    fontSize: { xs: '12px', md: '20px' },
                  }} color={'#7a7d9c'}>
                    {item.role}
                  </Typography>

                </Box>
              </Grid>
            ))}
          </Grid>
          <Typography className=' text-lg text-center ' sx={{
            fontWeight: 200,
            mt: 10,
            fontSize: { md: '24px', xs: '16px' },
            fontFamily: 'blinker',
            width:{xs:"80%",md:"100%"}
          }}>"We're ready to tackle your marketing challenges. <br />
            You're ready for exceptional results. <br />
            Let's join forces and create custom advertising strategies that drive conversions."</Typography>

          <Button href="/meeting" color='primary' className=' customBtnStyle ' sx={{
            mt: '40px'
          }}> Get Started </Button>
        </Grid>

        {/* <CountdownTimer targetDate="8/20/2024"></CountdownTimer> */}
      </Box>
    </div>

  );
};

export default About;
