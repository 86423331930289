import { Box, Button, Grid, Typography } from "@mui/material";
import { styled } from '@mui/system';
import { Link, useSearchParams } from "react-router-dom";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import SmallBtn from "../SmallBtn";
import FreeConsultationBtn from "../FreeConsultationBtn";
import LazyImage from '../lazyImage';

export default function AdsCampaignSection1() {
  return (
    <Grid container sx={{
        overflow:'hidden',
        // backgroundImage: `url("../back.png")`,
        backgroundImage:{xs:`url("../seocamp2.png")`, md:`url("../seocamp.png")`},
        height: "auto",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
        // backgroundSize: {xs:"contain", md:"cover"},
    //    textAlign:'center !important',
    //    justifyItems:"center !important",
    //    alignItems:"center !important",
    //    justifyContent:"center !important",
    //    alignContent:"center !important",
    //    backgroundColor:"red",
        
      }}>
        
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{
          display: "flex",
          flexDirection: "column",
          mt: { xs: 16, md: 8 },
          textAlign:"left",   
          marginLeft:{xs:"23vw",md:"25vw",xl:"33vw"},
          "@media (min-width: 3000px)":{
            marginLeft:"42vw",
                  },
          "@media (min-width: 6300px)":{
            marginLeft:"46vw",
                  },
        }}>
          {/* <Typography sx={{
            mt: '76px',
            fontFamily: "blinker",
            fontSize: '162px' ,
            color: '#182233',
            fontWeight: '700',
            backgroundColor:"red",
            display:"flew",
            lineHeight: { xs: '120px', md: '120px' },
          }}>LEFT <br /> TO<img className="w-[100px] h-[100px]" src={arrowCamp} alt="Arrow" /></Typography> */}
          <Typography sx={{
            fontFamily: "blinker",
            fontSize: { xs: '42px', md:'71px' },
            color: '#fff',
            fontWeight: '700',
            lineHeight: { xs: '58px', md:'100px' },
            marginTop: { xs: '80px', md:'50px' },
          }}>Welcome</Typography>
          <Typography
            sx={{
              fontFamily: "blinker",
              fontSize: { xs: "63px", md: "111px" },
              fontWeight: "700",
              lineHeight: { xs: "58px", md: "100px" },
            }}
          >
            <span
              style={{
                background: "linear-gradient(59deg, rgba(56,203,249,1) 0%, rgba(188,131,245,1) 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                display: "inline-block",
              }}
            >
              2025
            </span>
          </Typography>
          <Typography sx={{
            fontFamily: "blinker",
            fontSize: { xs: '30px', md:'71px' },
            color: '#fff',
            fontWeight: '700',
            lineHeight: { xs: '37px', md:'65px' },
            marginBottom:{ xs: '40px', md:'10px' },
          }}>with Bigger <br/> opportunities!</Typography>
          <Typography sx={{
            fontFamily: "blinker",
            fontSize: { xs: '12px', md: '14px',lg:'16px' },
            color: '#fff',
            maxWidth:{xs:"220px", md:"700px"},
            lineHeight: { md: '80px' },
            marginBottom:{ xs: '20px', md: '20px' },
          }}>Kick off the year by connecting your business with more customers and boosting sales!</Typography>
          <Box sx={{
            marginLeft:{xs:"-6vw",md:"0"},
          }}><FreeConsultationBtn text="Get Started Today" /></Box>
          <Typography sx={{
            fontFamily: "blinker",
            fontSize: { xs: '13px', md: '20px',lg:'16px' },
            color: '#fff',
            fontWeight: '400',
            lineHeight: '20px',
            marginTop:{ xs: '13px', md: '20px' },
            marginLeft:{ xs: '1vw', md: '2vw' },
          }}> Limited Time Offer</Typography>
        </Grid>
        <Grid item lg={4} />
        <Grid container className="mt-10 md:mt-20 justify-center">
        <Grid item lg={2} />
        </Grid>
      </Grid>
  );
}
