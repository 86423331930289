import { Box, Button, Typography, Modal } from '@mui/material'
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

export default function TicketModal({openModal , name , formattedDate }) {
  const router = useNavigate()
  const handleClose = () => {
    router(-1)
  };
  
  return (
      <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex !important',
          justifyContent: 'center !important',
          alignItems: 'center !important',
          height: '100vh !important', 
          padding:"10px !important"
        }}
      >
        <Box sx={{
          fontFamily: 'blinker',
          textAlign:'center',
          background: 'rgba(120, 141, 232, 1)',
          "@media (max-width: 1023px)":{
            width:"355px",
            height:"550px",
              },
          "@media (min-width: 1024px) and (max-width: 1439px)":{
            width:"680px",
            height:"500px",
              },
          "@media (min-width: 1440px)": { 
            width:"890px",
            height:"615px",
              },
          borderRadius: 8,
          marginTop:"50px",
          justifyItems:"center",
        }}>
          <Button sx={{
            color:'white',
            display: 'flex !important',
            paddingRight:"300px",
            mt:"10px",
            "@media (min-width: 1024px) and (max-width: 1439px)":{
                  paddingRight:"620px",
                  mt:"10px"
                  },
            "@media (min-width: 1440px)": { 
                  paddingRight:"820px",
                  mt:"20px"
                  },
          }} 
          size="large" onClick={handleClose}>
            <CloseIcon fontSize='large'  />
          </Button>
          <Typography className="text-white "  sx={{ 
            "@media (max-width: 1023px)":{
              mt:"0px" ,
              fontSize:'18px',
              width:"270px",
              padding: '0px 10px',
                },
            "@media (min-width: 1024px) and (max-width: 1439px)":{
              mt:"20px" ,
              fontSize:'23px',
                },
            "@media (min-width: 1440px)": { 
              mt:"30px" ,
              fontSize:'30px',
                },
            fontFamily: 'blinker', 
            fontWeight:'700'}}>
          Every challenge has a solution, and we're here to help!
          </Typography>
          <Typography id="modal-modal-description " className="text-stone-900 text-xl font-bold" sx={{
          fontFamily: 'Blinker', 
          background: "linear-gradient(37deg, rgba(159,253,244,0.01) 0%, rgba(49,230,174,1) 10%, rgba(208,241,231,1) 50%, rgba(27,228,166,1) 100%)",
          padding: '20px', 
          mt:{ xs: '20px', md: '40px' },
          "@media (max-width: 1023px)":{
              width:"270px",
              fontSize:'25px',
              padding: '60px 30px',
                },
          "@media (min-width: 1024px) and (max-width: 1439px)":{
              width:"550px" ,
              fontSize:'27px',
              padding:"40px 5px",
                },
          "@media (min-width: 1440px)": { 
              width:"750px" ,
              fontSize:'35px',
              padding:"60px 5px",
                },
          textAlign: 'center',
          borderRadius: '40px', 
        }}>
          Your ticket has been successfully
          <strong> submitted! </strong> 
          </Typography>
          <Typography id="modal-modal-description " sx={{
            // fontFamily: 'blinker',
            fontFamily:"blinker",
            mt:{ xs: '30px', md: '40px' },
            mx:{ xs: '53px', md: '56px' },
            px:{ xs: '0px', md: '70px' },
            // maxWidth:"400px",

            "@media (max-width: 1023px)":{
              fontSize:'18px',
                },
            "@media (min-width: 1024px) and (max-width: 1439px)":{
              fontSize:'23px',
              padding:"0 25px",
                },
            "@media (min-width: 1440px)": { 
              fontSize:'30px',
                },
               }}>
          Our team has received your request and will review it shortly. You can expect a response soon.
          </Typography>
          {/* <Typography id="modal-modal-description " sx={{ 
            mt:{ xs: '30px', md: '20px' } ,
            fontFamily:"blinker",
            mx:{ xs: '60px', md: '56px' },
            "@media (max-width: 1023px)":{
                fontSize:'13px',
                  },
              "@media (min-width: 1024px) and (max-width: 1439px)":{
                fontSize:'16px',
                  },
              "@media (min-width: 1440px)": { 
                fontSize:'24px',
                  },
            }}>
          We can’t wait to meet you and start this journey together!
          </Typography> */}
        </Box>
      </Modal>
    </div>
  )
}



// import { Box, Typography, Modal } from '@mui/material'
// import React from 'react'

// // import { useLocation } from "react-router-dom";
// import { useNavigate } from 'react-router-dom';

// export default function TicketModal({openModal}) {
//   const router = useNavigate()
//   const handleClose = () => {
//     router(-1)
//   };
  
//   return (
//     <>
//     <Box sx={{
//         mt:20
//     }}>
      
//     </Box>
//     <div>
//       <Modal
//         open={openModal}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//         sx={{
//             display:'flex', 
//             flexDirection:'column',
//             alignItems:'center',
//             justifyContent:'center'
//         }}
//       >
//         <Box sx={{
//         //   position: 'absolute',
//           top: { xs: '200px', md: '50vh'  },
//           left: { xs: '41px', md: '10vw'   },
//           fontFamily: 'blinker',
//           textAlign:'center',
//           backgroundColor: '#10F9B1',
//           width: { xs: '310px', md: '907px' },
//           height: { xs: '400px', md: '297px' },
//           borderRadius: 8,
//           display:'flex', 
//             flexDirection:'column',
//             alignItems:'center',
//             justifyContent:'center'

//         }}>
//           <Typography sx={{
//             fontSize:{md : '36px' , xs : '24px'},
//             color:'#000',
//             fontFamily:'blinker',
//             fontWeight:'700'
//           }}>
//           Your ticket has been submitted successfully
//           </Typography>
//           <Typography sx={{
//             fontSize:{md : '32px' , xs : '21px'},
//             color:'#000',
//             fontFamily:'blinker',
//             fontWeight:'200',
//             paddingTop:{xs:"30px", sm:"0"},
            
//           }}>
//           We will contact you soon.
//           </Typography>
//         </Box>
//       </Modal>
//     </div>
    
//     </>
//   )
// }