import React from 'react'
import BrandingHeroSection from './BrandingHeroSection'
import BrandingEndSection from './BrandingEndSection'
import BrandingSectionTwo from './BrandingSection2'
import BrandingSectionThree from './BrandingSectionThree'
import BrandingSectionFour from './BrandingSectionFour'
import { TabTitle } from '../../utils/GeneralFunctions'
import {Helmet} from "react-helmet";


function BrandingDesignPage() {
  return (
    <div className='text-white mb-[100px] overflow-hidden'>
      <Helmet>
    <title>Branding Services Canada | Logo, Identity & Digital Brand Strategy</title>
    <meta name="description" content="Build a strong brand identity with Beverly Digital. From logo design to full brand strategy, we help businesses in Canada create memorable, impactful brands." />
    <meta name="keywords" content="Branding services Canada, Logo design Toronto, Business identity design, Brand strategy experts, Creative brand agency, Visual branding solutions, Small business branding, Corporate identity development, Personal branding Canada, Professional logo creators" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
</Helmet>
      <BrandingHeroSection />
      <BrandingSectionTwo />
      <div className='relative seo-sec2-background-2 ' >
        <div className='sticky top-0'>
    <BrandingSectionThree />
    <BrandingSectionFour />
    </div></div>
    {/* <ContentSectionFive /> */}
      {/*
      
    <ContentSectionFour />
    

      
      <ContentEndSection /> */}
      <BrandingEndSection />
    </div>
  )
}

export default BrandingDesignPage
