import React, { useEffect, useState } from 'react';
import sec4 from "./sec4.json";
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import GetaProposalBtn from '../GetaProposalBtn';
import GetaProposalBtnPurple from '../GetaProposalBtnPurple';

function SectionFour() {
    const [data, setData] = useState(null);
    const [activeType, setActiveType] = useState(1);
    const [activeId, setActiveId] = useState(1);
    const [typeOptionActiveId, setTypeOptionActiveId] = useState(1);
    const [typeOptionItems, setTypeOptionItems] = useState([]);
    const [minTime2, setMinTime2] = useState(0);
    const [maxTime2, setMaxTime2] = useState(0);
    const [minPrice2, setMinPrice2] = useState(0);
    const [maxPrice2, setMaxPrice2] = useState(0);

    useEffect(() => {
        setData(sec4);
    }, []);

    // Reset min/max time and prices when activeType or optionItem changes
    useEffect(() => {
        setMinTime2(0);
        setMaxTime2(0);
        setMinPrice2(0);
        setMaxPrice2(0);
        setTypeOptionItems([]);
    }, [activeType, activeId, typeOptionActiveId]);

    const SelectType = (e) => {
        const id1 = e;
        setActiveType(id1);
        setActiveId(id1);
        const startQuestion = () => {
            window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: "tagChange"            
                });
            setStart(true);
          }
    };

    const selectTypeOption = (e) => {
        const idTarget = e;
        setTypeOptionActiveId(idTarget);
    };

    const selectItem = (id, minTime, maxTime, minPrice, maxPrice) => {
        if (typeOptionItems.includes(id)) {
            setTypeOptionItems(typeOptionItems.filter(item => item !== id));
            setMinTime2(prevMinTime2 => prevMinTime2 - minTime);
            setMaxTime2(prevMaxTime2 => prevMaxTime2 - maxTime);
            setMinPrice2(prevMinPrice2 => prevMinPrice2 - minPrice);
            setMaxPrice2(prevMaxPrice2 => prevMaxPrice2 - maxPrice);
        } else {
            setTypeOptionItems([...typeOptionItems, id]);
            setMinTime2(prevMinTime2 => prevMinTime2 + minTime);
            setMaxTime2(prevMaxTime2 => prevMaxTime2 + maxTime);
            setMinPrice2(prevMinPrice2 => prevMinPrice2 + minPrice);
            setMaxPrice2(prevMaxPrice2 => prevMaxPrice2 + maxPrice);
        }
    };

    return (
        <>
            <section className='container container-2 mb-33 md:mt-0 mt-56 h-custom overflow-y-auto overflow-x-hidden relative hidden md:block '>
                <div className='section4-top flex justify-start items-center'>
                    {/* <h3 className='section4-title text-[#10F9B1] text-[64px] ff-bold font-bold'>WHAT?</h3> */}
                    <div className='flex justify-start gap-3 ml-[50px]'>
                        <h2 className='text-[36px] font-[200] ff-bold text-[#10F9B1]'>Business | time required | price </h2>
                        {/* <span className='text-[24px] font-[400] ff-regular text-[#10F9B1]'>|</span>
                        <span className='text-[24px] font-[400] ff-regular text-[#10F9B1]'>long time</span>
                        <span className='text-[24px] font-[400] ff-regular text-[#10F9B1]'>|</span>
                        <span className='text-[24px] font-[400] ff-regular text-[#10F9B1]'>price</span> */}
                    </div>
                </div>
                <div className='section4-types-title mt-[35px] flex flex-col items-end'>
                    <div className='p-[20px] bg-[#283345]  section4-types-title1'>
                        {/* Start Main Type Selection */}
                        <div className='flex gap-3 rounded-full py-[5px] px-[5px] bg-dark w-[750px] justify-between items-center shadow-sec4-types-title relative sec4-types-flex'>
                            <div className='back-sec4-types' id={activeId === 1 ? "active-back" : ""}></div>
                            {data && data.map((item) => (
                                <button onClick={() => SelectType(item.id)} id={item.id === activeId ? "active-11" : ""} className='z-10 text-[21px] text-[#BC83F5] font-[400] ff-regular cursor-pointer bg-transparent rounded-full w-[50%] h-[50px] flex justify-center items-center text-center pt-[-20px] bdrs-50' data-id={item.id} style={{ color:activeId !== 1 ? "#51F6C3" : "#BC83F5"}}>
                                    {item.title}
                                </button>
                            ))}
                        </div>
                        {/* End Main Type Selection */}
                    </div>
                    <div className='section4-business-types w-full bg-[#283345] shadow-card-sec4 flex gap-[59px] relative z-10' id='options-type'>
                        {/* Start options type selection and sub options items*/}
                        {data && activeType === 1 && data.filter(option => option.id === 1).map((item) => (
                            item.options.map(optionItem => (
                                <div>
                                    <span className='sec4-business-types-title text-[16px] lg:text-[24px] font-[400] ff-regular text-white cursor-pointer' data-id={optionItem.id} key={optionItem.id} onClick={() => selectTypeOption(optionItem.id)} id={optionItem.id === typeOptionActiveId ? "active-33" : ""}>
                                        {optionItem.text}
                                    </span>

                                    <div className='w-full mt-[50px] flex flex-wrap gap-4 absolute left-0 max-h-[400px]' id={optionItem.id === typeOptionActiveId ? "z-1000" : ""}>
                                        {data && optionItem.id === typeOptionActiveId && optionItem.options.map((SubOptionitem) => (
                                            <div className='cursor-pointer z-[1000]'>
                                                <div className='sub-option-item cursor-pointer z-40 rounded-[18px]' data-id={SubOptionitem.id} id={typeOptionItems.includes(SubOptionitem.id) ? "active-44" : ""} onClick={() => selectItem(SubOptionitem.id, SubOptionitem.minTime, SubOptionitem.maxTime, SubOptionitem.minPrice, SubOptionitem.maxPrice)}>
                                                    {SubOptionitem.text}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))
                        ))}

                        {data && activeType === 2 && data.filter(option => option.id === 2).map((item) => (
                            item.options.map(optionItem => (
                                <div>
                                    <span className='sec4-business-types-title lg:text-[24px] text-[16px] font-[400] ff-regular text-white cursor-pointer' data-id={optionItem.id} key={optionItem.id} onClick={() => selectTypeOption(optionItem.id)} id={optionItem.id === typeOptionActiveId ? "active-55" : ""}>
                                        {optionItem.text}
                                    </span>

                                    <div className='w-full mt-[50px] flex flex-wrap gap-4 absolute left-0 max-h-[400px]' id={optionItem.id === typeOptionActiveId ? "z-1000" : ""}>
                                        {data && optionItem.id === typeOptionActiveId && optionItem.options.map((SubOptionitem) => (
                                            <div className='cursor-pointer z-[1000]'>
                                                <div className='sub-option-item  cursor-pointer z-40 rounded-[18px]' data-id={SubOptionitem.id} id={typeOptionItems.includes(SubOptionitem.id) ? "active-44" : ""} onClick={() => selectItem(SubOptionitem.id, SubOptionitem.minTime, SubOptionitem.maxTime, SubOptionitem.minPrice, SubOptionitem.maxPrice)}>
                                                    {SubOptionitem.text}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))
                        ))}

                        {!data && <span className='sec4-business-types-title text-[24px] font-[400] ff-regular text-white'>
                            Loading ...
                        </span>}
                    </div>
                </div>
                <div className='mt-[240px] left-4 flex justify-between flex-wrap items-center w-full'>
                    <div className='w-auto bg-[#182233] sec4-price py-7 px-5 flex justify-between gap-4'>
                        <p className='font-[400] ff-regular text-[24px]'>
                            Estimated cost & time →
                        </p>
                        {activeType === 1 ? <div className='flex gap-3'>
                            <p className='retail-price-text'>{minTime2} {maxTime2 === 0 ? '' : ' - ' + maxTime2} weeks</p>
                            <p className='retail-price-text'>${minPrice2} {maxPrice2 === 0 ? '' : ' - $' + maxPrice2}</p>
                        </div> : <div className='flex gap-3'>
                            <p className='startup-price-text'>{minTime2} {maxTime2 === 0 ? '' : ' - ' + maxTime2} weeks</p>
                            <p className='startup-price-text'>${minPrice2} {maxPrice2 === 0 ? '' : ' - $' + maxPrice2}</p>
                        </div>}
                    </div>

                    <div className='mr-10 mt-6'>
                        {activeType === 1 && <GetaProposalBtn />}
                        {activeType === 2 && <GetaProposalBtnPurple ></GetaProposalBtnPurple>}
                    </div>
                </div>
            </section>

            <section className='flex flex-col content-center justify-center md:hidden w-full min-h-[100svh] -mt-10 relative ' style={{
                // marginTop:'45px'
            }}>
                <div className='flex justify-center gap-3 items-center'>
                    {/* <h3 className='section4-title text-[#10F9B1] text-[20px] ff-bold font-bold'>WHAT?</h3> */}
                    <div className='flex justify-start gap-3 -mt-5'>
                        {/* <span className='text-[15px] font-[400] ff-regular text-[#FFFFFF]'>
                            business
                        </span>
                        <span className='text-[15px] font-[400] ff-regular text-[#FFFFFF]'>
                            |
                        </span>
                        <span className='text-[15px] font-[400] ff-regular text-[#FFFFFF]'>
                            long time
                        </span>
                        <span className='text-[15px] font-[400] ff-regular text-[#FFFFFF]'>
                            |
                        </span>
                        <span className='text-[15px] font-[400] ff-regular text-[#FFFFFF]'>
                            price
                        </span> */}
                        <h2 className='text-[18px] font-[200] ff-bold text-[#10F9B1] '>Business | time required | price </h2>
                    </div>
                </div>
                <div className='sec4-top-mobile flex flex-col gap-0 justify-center items-center mt-[24px]'>
                    <div className='p-[20px] bg-[#243146] sec4-type-title-mobile'>
                        {/* Start Main Type Selection */}
                        <div className='flex gap-3 rounded-full py-[5px] px-[5px] bg-dark w-[234px] justify-between items-center shadow-sec4-types-title relative sec4-types-flex'>
                            <div className='back-sec4-types-2' id={activeId === 1 ? "active-back-2" : ""}></div>
                            {data && data.map((item) => (
                                <button onClick={() => SelectType(item.id)} id={item.id === activeId ? "active-11" : ""} className='z-10 text-[18px] text-[#FFF] font-[400] ff-regular cursor-pointer bg-transparent rounded-full w-[45%] h-[35px] flex justify-center items-center text-center bdrs-50' data-id={item.id} style={activeId === 1 ? { color: '#10F9B1' } : { color: '#BC83F5' }}>
                                    {item.mobileText}
                                </button>
                            ))}
                        </div>
                        {/* End Main Type Selection */}
                    </div>
                    <div className='w-full py-3 bg-[#243146] mt-[-5px] px-3 overflow-x-auto overflow-y-hidden' id='custom-scroll'>
                        <div className='min-w-[650px] w-[fit-content] flex gap-10 items-center'>
                            {data && activeType === 1 && data.filter(option => option.id === 1).map((item) => (
                                item.options.map(optionItem => (
                                    <div>
                                        <div className='sec4-business-types-title text-[13px] lg:text-[24px] font-[400] ff-regular text-white cursor-pointer' data-id={optionItem.id} key={optionItem.id} onClick={() => selectTypeOption(optionItem.id)} id={optionItem.id === typeOptionActiveId ? "active-66-mobile" : ""}>
                                            {optionItem.text}
                                        </div>

                                        <div className='w-full mt-[50px] flex flex-wrap gap-4 absolute left-0 max-h-[22dvh] px-4  custom-scroll overflow-x-hidden overflow-y-auto' id={optionItem.id === typeOptionActiveId ? "z-1000" : ""}>
                                            {data && optionItem.id === typeOptionActiveId && optionItem.options.map((SubOptionitem) => (
                                                <div className='cursor-pointer z-[1000]'>
                                                    <div className='sub-option-item cursor-pointer z-40 text-[13px] font-[400] ff-regular overflow-y-auto rounded-[12px]' data-id={SubOptionitem.id} id={typeOptionItems.includes(SubOptionitem.id) ? "active-44" : ""} onClick={() => selectItem(SubOptionitem.id, SubOptionitem.minTime, SubOptionitem.maxTime, SubOptionitem.minPrice, SubOptionitem.maxPrice)}>
                                                        {SubOptionitem.text}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))
                            ))}

                            {data && activeType === 2 && data.filter(option => option.id === 2).map((item) => (
                                item.options.map(optionItem => (
                                    <div>
                                        <div className='sec4-business-types-title text-[13px] lg:text-[24px] font-[400] ff-regular text-white cursor-pointer' data-id={optionItem.id} key={optionItem.id} onClick={() => selectTypeOption(optionItem.id)} id={optionItem.id === typeOptionActiveId ? "active-77-mobile" : ""}>
                                            {optionItem.text}
                                        </div>

                                        <div className='w-full mt-[50px] flex flex-wrap gap-4 absolute left-0 max-h-[140px] px-4 custom-scroll overflow-x-hidden overflow-y-auto' id={optionItem.id === typeOptionActiveId ? "z-1000" : ""}>
                                            {data && optionItem.id === typeOptionActiveId && optionItem.options.map((SubOptionitem) => (
                                                <div className='cursor-pointer z-[1000]'>
                                                    <div className='sub-option-item text-[13px] font-[400] ff-regular cursor-pointer z-40 rounded-[12px]' data-id={SubOptionitem.id} id={typeOptionItems.includes(SubOptionitem.id) ? "active-44" : ""} onClick={() => selectItem(SubOptionitem.id, SubOptionitem.minTime, SubOptionitem.maxTime, SubOptionitem.minPrice, SubOptionitem.maxPrice)}>
                                                        {SubOptionitem.text}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))
                            ))}
                        </div>
                    </div>
                </div>

                <div className='w-full bg-[#182233] mt-[30dvh] left-0 flex flex-col justify-center items-center gap-4'>
                    <p className='font-[400] ff-regular text-[20px]'>
                        Estimated cost & time →
                    </p>
                    {activeType === 1 ? <div className='flex gap-3 mt-0'>
                        <p className='retail-price-text-2 text-center'>{minTime2} {maxTime2 === 0 ? '' : ' - ' + maxTime2} weeks</p>
                        <p className='retail-price-text-2 text-center'>${minPrice2} {maxPrice2 === 0 ? '' : ' - $' + maxPrice2}</p>
                    </div> : <div className='flex gap-3'>
                        <p className='startup-price-text-2 text-center'>{minTime2} {maxTime2 === 0 ? '' : ' - ' + maxTime2} weeks</p>
                        <p className='startup-price-text-2 text-center'>${minPrice2} {maxPrice2 === 0 ? '' : ' - $' + maxPrice2}</p>
                    </div>}

                    <div className='mt-4'>
                        {activeType === 1 && <GetaProposalBtn />}
                        {activeType === 2 && <GetaProposalBtnPurple ></GetaProposalBtnPurple>}
                    </div>
                </div>
            </section>
        </>
    );
}

export default SectionFour;
