import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowAccordionOpen from './AccordionOpen.svg';
import ArrowAccordionClose from './AccordionClose.svg';

function SectionOne({onScrollStatusChange}) {

    // const handleScroll = (e) => {
    //     // const { scrollTop, scrollHeight, clientHeight } = e.target;
    //     // onScrollStatusChange(false);
    //     console.log(e)
    //     // const { scrollTop, scrollHeight, clientHeight } = e.target;
    //     // console.log(scrollTop , scrollHeight , clientHeight)
    //     // if (scrollTop === 0 || scrollTop + clientHeight >= scrollHeight) {
    //     //     console.log("first")
    //     // //   Unlock parent scroll when at the start or end of the child scroll
    //     //   onScrollStatusChange(false);
    //     // } else {
    //     //   // Lock parent scroll when child is actively scrolling
    //     //   onScrollStatusChange(true);
    //     // }

    //     // console.log("Touch")
        
    //   };

    //   const handleScrollRemove = (e) => {
    //     // onScrollStatusChange(false);
    //     // const { scrollTop, scrollHeight, clientHeight } = e.target;
    
    //     // if (scrollTop === 0 || scrollTop + clientHeight >= scrollHeight) {
    //     //     console.log("first")
    //     // //   Unlock parent scroll when at the start or end of the child scroll
    //     //   onScrollStatusChange(false);
    //     // } else {
    //     //   // Lock parent scroll when child is actively scrolling
    //     //   onScrollStatusChange(true);
    //     // }

    //     // console.log(onScrollStatusChange)
    //   }

      const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
    
        if (scrollTop === 0) {
            // User is at the start of the scrollable content
            console.log("start");
            onScrollStatusChange(false);
        } else if (scrollTop + clientHeight >= scrollHeight) {
            // User is at the end of the scrollable content
            console.log("end");
            onScrollStatusChange(false);
        } else {
            // User is somewhere in the middle
            console.log("middle");
            onScrollStatusChange(true);
        }
    };

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false); // Set the expanded state based on the clicked accordion
    };

    const Items = [
        {
            "id": 1,
            "title": "a modern website to position my brand.",
            "title2": "Elevate Your Brand with Crafty Design and SEO",
            "desc": "At Beverly, we create visually captivating websites that enhance SEO, boost online visibility, and attract loyal customers."
        },
        {
            "id": 2,
            "title": "to update my brand image.",
            "title2": "Empower Your Brand with Our Expertly Crafted Brand Book",
            "desc": "We offer a strategic brand book that empowers your team to embody your brand's essence, ensuring consistent messaging and a unified experience."
        },
        {
            "id": 3,
            "title": "my social media and website content to attract new customers",
            "title2": "Elevate Your Content Strategy with Engaging Digital Solutions",
            "desc": "We deliver SEO-optimized, trend-aligned digital content through a strategic calendar approach that drives results and effectively reaches your target audience."
        },
        {
            "id": 4,
            "title": "an ADS campaign for my new product or service.",
            "title2": "Amplify Your Reach with Eye-Catching Ads",
            "desc": "We create targeted, eye-catching ads and campaigns that effectively promote your products and services to the right audience at the right time, driving impactful results."
        },
        {
            "id": 5,
            "title": "to effectively market my new service to potential customers",
            "title2": "Launch Your Service with a Targeted Strategy",
            "desc": "We provide a strategic approach that combines social media, ads, and SEO to introduce your new service to the perfect audience, driving excitement and results."
        }
    ]

    return (
        <div className='section1-main  lg:mt-0  lg:h-[100vh] xl:mt-0 overflow-hidden  pt-0 lg:block flex sm:h-[100vh] h-[85vh]'>
            {/* <div className='section1-background z-0  h-[90vh] w-1/2'></div> */}
            <div className='section1-content z-10  lg:block h-[100dvh] flex sm:justify-start flex-col items-center justify-center overflow-hidden'>
                <h3 style={{
                    fontFamily:'blinker'
                }} className='text-[20px] sm:text-[64px] text-[#10F9B1] w-full font-extrabold px-0 lg:pl-24 lg:text-start text-center'>
                    HOW WE WORK
                </h3>

                <div className='section1-card-swiper my-10 w-full hidden lg:block'>
                    <Swiper
                        // style={{paddingLeft:'150px'}}
                        // spaceBetween={10}
                        style={{
                            // width:'100%',
                            
                            marginLeft:'20px'
                        }}
                        slidesPerView={2.5}
                        freeMode={true}
                        onSlideChange={() => console.log('slide change')}
                        // onSwiper={(swiper) => console.log(swiper)}
                        dir='ltr'
                        // loop
                        breakpoints={{
                            640: {
                                slidesPerView: 1.1,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 1.2,
                                spaceBetween: 10,
                            },
                            1024: {
                                slidesPerView: 2.5,
                                spaceBetween: 10,
                            },
                            1366: {
                                slidesPerView: 2.9,
                                spaceBetween: 10,
                            },
                        }}
                    >
                        {
                            Items.map((item) => (
                                <SwiperSlide style={{
                                    width:'543px !important',
                                    
                                }} className='p-2' key={item.id} >
                                    <div className='section1-card-swiper-item '>
                                        <p className='card-swiper-item-title font-bold text-[40px] text-[#DEE2E6]'>
                                            I Need
                                        </p>
                                        <div className='card-swiper-item-content h-[400px] w-full flex gap-3'>
                                            <div className='swiper-item-content-border relative w-[5px] h-full'>
                                                <div className='h-full w-[0.5px] bg-white absolute left-0'></div>
                                                <div className='swiper-item-content-bullet w-[10px] h-[10px] rounded-full bg-[#10F9B1] absolute top-[3.5rem] left-[-3.5px] ml-[-1.5px]'></div>
                                                <div className='swiper-item-content-line-hover w-[3px] rounded-full bg-[#10F9B1] absolute top-[3.48rem] left-[-1px]'></div>
                                                <div className='swiper-item-content-bullet w-[10px] h-[10px] rounded-full bg-[#10F9B1] absolute top-[13.7rem] left-[-3.5px] ml-[-1.5px]'></div>
                                            </div>

                                            <div className='swiper-item-content-main'>
                                                <p className='mt-[21px] text-[20px] font-[400] leading-[30px]'>
                                                    {item.title}
                                                </p>

                                                <div className='mt-[120px] w-full'>
                                                    <p className='hover-green text-[20px] font-[400] leading-[30px]'>
                                                        {item.title2}
                                                    </p>

                                                    <p className=' text-[15px] font-[200] w-[356px] px-7 mt-5 Blinker-Thin leading-[22.5px] line-clamp-3'>
                                                        {item.desc}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='hover-green card-swiper-item-title -mt-5 font-bold text-[40px] text-[#DEE2E6]'>
                                            we do
                                        </p>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
                <div className=''>
                <div className='  h-[55dvh] lg:hidden p-3 sm:h-[65vh]  overflow-y-auto'  onScroll={handleScroll} style={{
                    borderRadius:'12px',
                    background: 'linear-gradient(224.65deg, rgba(187, 198, 243, 0.11) -17.42%, rgba(0, 0, 0, 0.04) 132.65%)',
                    width:'90dvw',
                    // height:'100svh',
                    // marginBottom:'40px'
                    
                }}>
                    <div className='bg-glass-1 '>
                        <div>
                            <p className='text-[24px] font-bold text-[#DEE2E6]'>
                                I Need
                            </p>
                            <hr className='my-3' />
                        </div>
                        <div className='mt-[10px] ' >
                            {Items.map((item2) => (
                                <Accordion expanded={expanded === item2.id} onChange={handleChange(item2.id)} className='accordion-section1 relative my-5' sx={{
                                    pb:item2.id === 5 ? '60px' : 0
                                }} key={item2.id}>
                                    <AccordionSummary
                                        className='text-white ff-regular accordion-header'
                                        // expandIcon={expanded === item2.id ? <ArrowAccordionClose /> : <ArrowAccordionOpen />}
                                        expandIcon={expanded === item2.id ? <img style={{
                                            width:'31px',
                                            height:'21px'
                                        }} src={ArrowAccordionClose} alt="Close" /> : <img style={{
                                            width:'31px',
                                            height:'21px'
                                        }} src={ArrowAccordionOpen} alt="Open" />}

                                        aria-controls="panel1-content"
                                        id="panel1-header">
                                        <Typography className='text-white ff-regular pt-[10px] flex gap-2 w-[190px]  flex-row items-center '>
                                            <div className='accordion-bullet ml-[0.5px] mt-[10px]  z-10'></div>
                                            <p className='text-white font-[400] text-[16px] ml-[10px] h-[48px]'>
                                                {item2.title}
                                            </p>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className='mt-[20px] relative' sx={{
                                        // mb:item2.id === 5 ? '60px' : 0
                                    }}>
                                        <div className='accordion-bullet ml-[0.5px] mt-[13px] z-20 absolute'></div>
                                        <div className='accordion-content px-5 mt-[-20px]'>
                                            <p className='text-[15px] font-[600]'>we do</p>
                                            <p className='font-[600] text-[14px] text-[#10F9B1] my-3'>
                                                {item2.title2}
                                            </p>
                                            <Typography sx={{
                                                    fontSize:'14px',
                                                    fontFamily:'blinker',
                                                    fontWeight:'100'
                                            }} className='text-white '>
                                                {item2.desc}
                                            </Typography>
                                        </div>
                                    </AccordionDetails>
                                    <div className={expanded ? 'h-[100px] accordion-line top-[110px]' : 'accordion-line h-[0px] top-[100px]'}></div>
                                </Accordion>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div >
    )
}

export default SectionOne
