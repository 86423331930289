import { Box, Button, Typography } from '@mui/material';
import React, {useState, useRef } from 'react';
import { TabTitle } from '../../utils/GeneralFunctions';
import MeetingCalendar from './meetingCalender';
import MeetingForm from './meetingForm';
import CalendarModal from '../modalTest/calendarModal';
// import { Alert } from '@mui/material';
// import axios from 'axios';
// import { ScheduleMeeting } from 'react-schedule-meeting';
// import { timeSlotDifference } from 'react-schedule-meeting';
// import CircleLoader from '../circleLoader'
// import svg from '../SVG/discord-icon.svg'


export default function MeetingPage() {
    // const apiUrl = process.env.REACT_APP_API_URL;
    // const [errors, setErrors] = useState({});
    const [selectedUserTime, setSelectedUserTime] = useState('')
    // const [email, setEmail] = useState()
    // const [load, setLoad] = useState(true)
    const [openModal, setOpenModal] = useState(false)

    TabTitle("Meeting")
    const [isFormVisible, setIsFormVisible] = useState(false);
    // const sleep = ms => new Promise(r => setTimeout(r, ms));
    const [formattedDate, setFormattedDate] = useState('')
    const [combinedDateTime, setCombinedDateTime] = useState('')
    const handleTimeSelect = (formattedDate) => {
        // console.log('Hello',formattedDate);
        setSelectedUserTime(formattedDate);
        
        // sleep(1000);
        
        if(formattedDate !== ''){
            setIsFormVisible(true);
            setFormattedDate(formattedDate);
        }
        
    };
    const handleTimeSelectModal = (combinedDateTime) => {
        if(combinedDateTime !== ''){
            setCombinedDateTime(combinedDateTime);
            console.log('Hello',combinedDateTime);
        }
    };
    // const compRef = useRef()
    // const [showAlert, setShowAlert] = useState(false);

    // const handleButtonClick = () => {        
        
    //     if(selectedUserTime !== ''){
    //         setIsFormVisible(true);
    //     }else{
    //         // setShowAlert(true);
    //         // setTimeout(() => setShowAlert(false), 3000);        
    //     }
    //     // if(isFormVisible){
    //     // console.log(compRef)
    //     // compRef.current.SubmitData()}
        
             
    // };
    

    return (
        <>
            
            <div className="h-[100%] w-[100vw]  mt-[100px] flex items-center justify-center px-4 mb-20 ">
                <div className="w-full  flex flex-col lg:flex-row gap-6 lg:mx-5 xl:mx-10 ">
                    <div
                        className=" lg:h-[100vh] w-[100%] flex flex-col gap-3 align-center justify-center items-center pb-4 pt-6"
                        style={{
                            paddingBottom: '40px',
                            backgroundColor: '#182233',
                            borderRadius: '50px',
                        }}
                    >
                        <Box
                            className="flex-col lg:flex-row items-center "
                            sx={{
                                display: "flex",
                                // gap: 2,
                                // justifyContent: "space-between",
                                // flexDirection: "column",
                                alignItems: "center",
                                // backgroundColor:"#8E1616",
                                "@media (min-width: 1024px) and (max-width: 1439px)":{
                                        width:"950px",
                                    },
                                "@media (min-width: 1440px)": { 
                                         width:"1340px",
                                    },
                            }}
                            >

                            <Box
                                sx={{
                                    // minWidth : "40vw !important",
                                    // backgroundColor:"#D84040",
                                    "@media (min-width: 1024px) and (max-width: 1439px)":{
                                            marginTop:"-200px",
                                            width:"1500px",
                                        },
                                    "@media (min-width: 1440px)":{
                                            marginTop:"-200px",    
                                            width:"1200px",
                                        },
                                }}
                                >
                            <Typography
                                sx={{
                                    fontFamily: 'Blinker',
                                    fontWeight: '600',
                                    "@media (max-width: 1023px)":{
                                        fontSize: '31px',
                                        },
                                    "@media (min-width: 1024px) and (max-width: 1439px)":{
                                        fontSize: '38px',
                                        // paddingRight:'-100px',
                                        width:'315px',
                                        },
                                    "@media (min-width: 1440px)":{
                                        fontSize: '48px',
                                        },

                                    // fontSize: {xs : '31px' , md: '38px', lg: '48px'},
                                }}
                            >
                                Need Consultation?
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Blinker',
                                    // fontSize:{
                                    //     sx:"23px",
                                    //     md:"28px",
                            
                                    // },
                                    fontWeight: '300',
                                    "@media (max-width: 1023px)":{
                                        fontSize: '23px',
                                        },
                                    "@media (min-width: 1024px) and (max-width: 1439px)":{
                                        fontSize: '22px',
                                        },
                                    "@media (min-width: 1440px)":{
                                        fontSize: '28px',
                                        },
                                }}
                            >
                                Book a Free Call in <strong>Toronto</strong> Time
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Blinker',
                                    fontSize: '13px lg:16px',
                                    fontWeight: '300',
                                    width:"300px",
                                    padding:{
                                        sx:"0px",
                                        md:"40px 0",
                                    },
                                }}
                            >
                                Schedule a 30 minute call to meet, share your website needs and discover the benefits of a beverly website.
                                <br></br>Book a call with us to:
                            </Typography>
                            <Typography 
                                sx={{
                                    fontFamily: 'Blinker',
                                    fontSize: '13px lg:16px',
                                    fontWeight: '300',
                                    width:'300px',
                                    marginBottom:'40px'
                                }}
                            >
                                ✔️Talk to a human<br></br>
                                ✔️Share <br></br>
                                ✔️How BEVERLY would help you
                            </Typography>
                            </Box>
                            <Box
                                sx={{
                                    "@media (min-width: 500px) and (max-width: 1020px)": {
                                          margin: "0 10vw !important",
                                        },
                                    // width:"800px",
                                    // position:'absolute',
                                    // left:"39vw",

                                }}
                            >
                            {!isFormVisible ? (
                                <MeetingCalendar onTimeSelect={handleTimeSelect} onTimeSelectModal={handleTimeSelectModal} />
                            ) : ( 
                                <>
                                {console.log('combinedDateTime ', {combinedDateTime} )}
                                <MeetingForm  date={selectedUserTime} formattedDate={combinedDateTime} />
                                </>
                            )}
                            </Box>
                            
                            </Box>

                            {/* {showAlert && (
                                <Alert 
                                severity="warning"
                                sx={{
                                    backgroundColor: 'transparent',
                                    color: '#ffffff',
                                    fontSize: '18px',
                                    border: '1px solid #10F9B1',
                                    borderRadius: '8px',
                                    padding: '10px 20px',
                                    marginRight: '5%',
                                }}
                                >
                                    Specifying a time for the meeting is mandatory.
                                </Alert>
                            )} */}
                        {/* <div className='lg:flex lg:flex-row-reverse lg:w-[70vw] lg:-mr-44 xl:mr-[7vw] '> */}
                        {/* <div className='lg:flex lg:flex-row-reverse lg:w-[70vw] absolute top-[1250px] left-[100px] '>
                        {!isFormVisible &&  <Button
                            disabled={!load}

                            variant="contained"
                            color="primary"
                            className=" text-white  font-bold lg:w-1/4  w-[70vw] "
                            onClick={handleButtonClick}
                            sx={{
                                maxWidth: '40vw',
                                // marginRighta:'50px',

                              }}
                        >
                            Submit
                        </Button>}
                        </div> */}

                    </div>
                </div>
            </div>
        </>
    );
}
