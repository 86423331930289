import { Box,  Button, Container, Grid, Typography } from "@mui/material";
import React, { Suspense, useState } from "react";
import { Link } from "react-router-dom";
import dentist from './dentist/assets/dentBG.png';
import rux from './rux/assets/ruxBG.png';
import dent from './doc.png';
import dentist2 from './dentist/assets/dentBG2.png';
import rux2 from './rux/assets/ruxBG2.png';
import dent2 from './doc2.png';

// import {Breadcrumbs} from "@mui/material/Breadcrumbs"
import '../../Library/css/cases.css';
import CircleLoader from "../circleLoader";
import LazyImage from "../lazyImage";
import {Helmet} from "react-helmet";

const Works = () => {
  // const [mEnter, setMEnter] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null); // State to track the hovered item
  const [loadedImages  ] = useState([false,false,false,false])

  const loadImages = (index) => {
      loadedImages[index] = true
      // console.log(loadedImages)
  }
  const [items] = useState([
    { id: 'dentist', link: 'dentist', imageUrl: dentist, imageMobileUrl: dentist2,text: 'Designing a landing page for a dental specialist in torento',subtitle : 'Visual Identity', title: 'for Dental 4', bgColor: 'linear-gradient(90deg, #FF77E6 10%,#63708380 47%,#67748645 75%, rgba(255,255,255,0) 80%)' , bgColorMobile:'linear-gradient(0deg, #FF77E6 15%,#63708380 57%,#67748645 75%, rgba(255,255,255,0) 80%)', clr: '#F584CF' },
    { id: 'rux', link: 'rux', imageUrl: rux,     imageMobileUrl: rux2,            text: 'Designing a corporation identity for rux business',subtitle : 'Visual Identity', title: 'for  Rux ',              bgColor: 'linear-gradient(90deg, #2CBB70 10%,#63708380 47%,#67748645 75%, rgba(255,255,255,0) 80%)' , bgColorMobile:'linear-gradient(0deg, #2CBB70 15%,#63708380 57%,#67748645 75%, rgba(255,255,255,0) 80%)', clr: '#52D490' },
    // { id: '1', link: '', imageUrl: dent,    imageMobileUrl: dent2,                text: 'Designing a corporation identity for rux business',subtitle : 'Website', title: 'for Exam partner',               bgColor: 'linear-gradient(0deg, #FA7E5A 10%,#63708380 47%,#67748645 75%, rgba(255,255,255,0) 80%)'  , bgColorMobile:'linear-gradient(0deg, #FA7E5A 15%,#63708380 57%,#67748645 75%, rgba(255,255,255,0) 80%)', clr: '#fb977b' },
    // { id: '2', link: '', imageUrl: dentist, imageMobileUrl: dentist2,             text: 'Designing a corporation identity for rux business',subtitle : 'Visual Identity', title: 'for Dental 4',           bgColor: 'linear-gradient(90deg, #9AAAEE 10%,#63708380 47%,#67748645 75%, rgba(255,255,255,0) 80%)' , bgColorMobile:'linear-gradient(0deg, #9AAAEE 15%,#63708380 57%,#67748645 75%, rgba(255,255,255,0) 80%)', clr: '#9abeee' },

  ]);

  
  return (

    <Suspense fallback={<CircleLoader isVisible={true}/>}>
    
    <div role="presentation">
    <Helmet>
    <title>Case Studies | Real Business Growth with Beverly Digital</title>
    <meta name="description" content="Explore real success stories from businesses that partnered with Beverly Digital. See how our branding, SEO, web design, and digital marketing strategies drive results." />
    <meta name="keywords" content="Case studies, Digital marketing success stories, Branding case studies, SEO results, Web design success, Advertising case studies, Business growth strategies, Marketing ROI, Client success stories, Local business transformation" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
</Helmet>
    </div>
      <div style={{
        // backgroundImage: 'url(worksBG.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '120vh',
        backgroundAttachment: 'fixed',
        backgroundPositionY: 'bottom',
        marginTop: '10px',
        
      }}>
        <Container sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          mt: {md : 12, xs : -25 },
        }}>{loadedImages}
          
          <Grid container spacing={5} sx={{
            display: 'flex',
            alignItems: 'center',
            mt: {md : 10, xs : 0},
            mb: '300px',
            transition: 'ease',
            transitionDelay: '0.5s',
            transitionDuration: '0.5s'
          }}>
            {items.map((item, index) => (
              <Grid
                
                item
                lg={6}
                md={6}
                xs={12}
                key={index}
                sx={{
                  display: {md : 'flex', xs : 'none' },
                  flexDirection: 'column',
                  alignItems: 'center',
                  position: 'relative',
                  
                }}
                onMouseEnter={() => setHoveredItem(item.id)} // Set hovered item
                onMouseLeave={() => setHoveredItem(null)} // Clear hovered item
              >

                <Link
                  to={{
                    pathname: `/case-study/${item.link}`,
                    state: { item }
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  <div className="coverCase" style={{
                    position: 'relative',
                    width:{md :'590px', xs: 'auto'},
                        height: {md :'268px', xs: 'auto'},

                    // height: '268px',
                    marginBottom: '10px',
                    
                    display: 'flex',
                    alignItems: 'left',
                    justifyContent: 'left',  // Center horizontally and vertically
                  }}>
                    <div className="coverCase" style={{
                      zIndex: 10,
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      bottom: '0',
                      left: '50%',
                      
                      transform: 'translateX(-50%)',
                      // background: 'linear-gradient(360deg, rgba(255,255,255,1) 0%, rgba(0,212,255,0) 80%)',

                      background: item.bgColor,
                      color: '#7a7d9c',
                      border: 'none',
                      padding: '10px 20px',
                      borderRadius: '20px',
                      cursor: 'pointer',
                      textAlign: 'center',
                      alignContent: 'end',

                    }}>

                    </div>
                    <LazyImage
                      onLoad={loadImages(index)}
                      style={{
                        width:{md :'590px', xs: 'auto'},
                        height: {md :'268px', xs: 'auto'},
                      }}
                      clss='rounded-[20px]'
                      src={item.imageUrl}
                      alt=""
                    />
                    
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'left',
                      width: '400px',
                      // height: '120px',
                      position: 'absolute',
                      // bottom: '20%',
                      left: {md:'40%' , xs : '50%'},
                      zIndex: '20',
                      transition: hoveredItem === item.id ? '1s ease !important' : '1s ease !important',
                      top: hoveredItem === item.id ? '15px' : '50px',
                      transform: 'translateX(-50%)',
                      // backgroundColor:'#fff',
                      
                      
                      textAlign:'left',
                      padding: 6,
                      // justifyContent: 'left'
                    }}>
                      <Typography sx={{
                        zIndex: 4,
                        fontFamily: 'blinker',
                        textAlign:'left',
                        fontSize: '20px',
                        fontWeight: '200',
                        backgroundColor: 'transparent',
                        color: '#182233',
                        mt: {md : -4 , xs : -8}
                      }}>{item.subtitle} </Typography>
                      <br />
                      <Typography sx={{
                        zIndex: 4,
                        fontFamily: 'blinker',
                        mt:-4,
                        fontSize: '32px',
                        textAlign:'left',
                        fontWeight: '800',
                        backgroundColor: 'transparent',
                        color: '#182233',
                        // mb: 4
                      }}>{item.title} </Typography>
                      

                    </Box>
                    <Typography  sx={{
                      mb: 2,

                      // display: 'none',
                      color: '#fff',
                      fontFamily: 'Blinker',
                      position: 'absolute',
                      bottom: '25%',
                      left: {md:'25%' , xs : '35%'},
                      width: '200px',
                      textAlign: 'center',
                      transform: {md : 'translateX(-40%)' , xs : 'translateX(-50%)'},
                      zIndex: '100',
                      transition: hoveredItem === item.id ? '1s ease !important' : '1s ease !important',
                      opacity: hoveredItem === item.id ? 1 : 0,
                    }}>
                      {item.text}

                    </Typography>
                    <Button style={{

                    }} sx={{

                      position: 'absolute',
                      bottom: {xs : '10%', md : '10%'},
                      left: {md:'27%' , xs : '40%'},
                      // display:'none',
                      transform: 'translateX(-50%)',
                      backgroundColor: item.clr,

                      color: 'black',
                      boxShadow: '5px 5px 5px #91517B60',
                      transition: hoveredItem === item.id ? '1s ease !important' : '1s ease !important',
                      opacity: hoveredItem === item.id ? 1 : 0,
                      // opacity:'0',
                      border: 'none',
                      padding: '10px 20px',
                      zIndex: '10',
                      width: '167px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      // backgroundColor: 'red',
                      // color: '#000'
                    }}>
                      See More
                    </Button>
                  </div>
                </Link>

              </Grid>
            ))}
          </Grid>
                    {/* ------------------------------------  Mobile Devices------------------------------------ */}
          <Grid className="flex md:hidden">
          <Grid container spacing={2} sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 0,
            mb: '300px',
            // mx:'20px',
          }}>
            {items.map((item, index) => (
              <Grid
                item
                lg={6}
                md={6}
                xs={12}
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >

                <Link
                  to={{
                    pathname: `/case-study/${item.link}`,
                    state: { item }
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  <div  style={{ //className="coverCase"
                    position: 'relative',
                    width: '300px',
                    height: '520px',
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'left',
                    justifyContent: 'left',  
                    overflow:'hidden',
                  }}>
                    <div className="" style={{ //coverCase
                      zIndex: 10,
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      top: '0',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      background: item.bgColorMobile,
                      color: '#7a7d9c',
                      border: 'none',
                      padding: '10px 20px',
                      borderRadius: '20px',
                      cursor: 'pointer',
                      textAlign: 'center',
                      alignContent: 'end',
                      

                    }}>

                    </div>
                    <LazyImage
                      onLoad={loadImages(index)}
                      clss='rounded-[20px]'
                      src={item.imageMobileUrl}
                      alt=""
                    />
                    
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '400px',
                      position: 'absolute',
                      left: '75%',
                      zIndex: '20',
                      top: '240px',
                      transform: 'translateX(-70%)',
                      textAlign:'left',
                    }}>
                      <Typography sx={{
                        zIndex: 4,
                        fontFamily: 'blinker',
                        textAlign:'left',
                        fontSize: '20px',
                        fontWeight: '500',
                        backgroundColor: 'transparent',
                        color: '#182233',
                        mt: 0
                      }}>{item.subtitle} </Typography>
                      <br />
                      <Typography sx={{
                        zIndex: 4,
                        fontFamily: 'blinker',
                        mt: -2,
                        fontSize: '23px',
                        textAlign:'left',
                        fontWeight: '1000',
                        backgroundColor: 'transparent',
                        color: '#182233',
                      }}>{item.title} </Typography>
                      

                    </Box>
                    <Typography  sx={{
                      mb: 0,
                      color: '#fff',
                      fontFamily: 'Blinker',
                      position: 'absolute',
                      bottom: '25%',
                      left: '17%',
                      width: '200px',
                      textAlign: 'center',
                      zIndex: '100',
                      fontSize: '13px',
                    }}>
                      {item.text}

                    </Typography>
                      <Button 
                        sx={{
                        position: 'absolute',
                        bottom: '10%',
                        left: '23%',
                        backgroundColor: item.clr,
                        color: 'black',
                        boxShadow: '5px 5px 5px #91517B60',
                        border: 'none',
                        padding: '10px 20px',
                        zIndex: '10',
                        width: '167px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: '12px',
                      }}>
                        See More
                    </Button>
                  </div>
                </Link>

              </Grid>
            ))}
          </Grid>
          </Grid>
          
        </Container>
      </div>
      </Suspense>
  );
}

export default Works;
