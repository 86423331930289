
import { Box, Button, Typography } from "@mui/material";
import React from 'react';
import SmallBtn from "../SmallBtn";
import LazyImage from '../lazyImage';

export default function AdsCampaignSection2({ 
  title,
  titleimage,
  description, 
  price, 
  discountPrice, 
  features, 
  isPopular 
}) {
  return (
    <Box
      sx={{
        backgroundColor: "black",
        borderRadius: "18px",
        margin: "20px",
        padding: "30px",
        maxWidth:"320px"
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems:"center"
        }}
      >
        <LazyImage
          src={titleimage}
          clss="w-8 h-8 lg:w-12 lg:h-12 mb-4 "
          alt="Search Icon"
        />
        <SmallBtn text={isPopular} />
      </Box>

      {/* Title */}
      <Box
        sx={{
          display: "flex",
          color: "#10F9B1",
          fontFamily: "Blinker",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "23px", md: "32px" },
            fontWeight: "800",
            fontFamily: "Blinker",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "13px", md: "21px" },
            fontWeight: "800",
            alignContent:"end",
            marginLeft: "10px",
            fontFamily: "Blinker",
          }}
        >
          Package
        </Typography>
      </Box>

      {/* Description */}
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "600",
          fontFamily: "Blinker",
        }}
      >
        {description}
      </Typography>

      <hr className="text-white my-5" />

      {/* Features */}
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "600",
          fontFamily: "Blinker",
        }}
      >
        Includes:
      </Typography>
      {features.map((feature, index) => (
        <Box key={index} >
          <LazyImage
            src="Star.png"
            clss="w-[20px] h-[20px] my-3 lg:my-5"
            alt="Star Icon"
          />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              fontFamily: "Blinker",
            }}
          >
            {feature}
          </Typography>
        </Box>
      ))}

      <hr className="text-white my-5" />

      {/* Pricing Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontFamily: "Blinker",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "12px", md: "14px" },
            fontFamily: "Blinker",
            color: "#10F9B1",
          }}
        >
          Original value
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "20px", md: "28px" },
            fontFamily: "Blinker",
            margin: { xs: "5px 0", md: "10px 0" },
          }}
        >
          $<div className="relative inline-block text-xl md:text-2xl ">
            <span>{price}</span>
            <span className="absolute top-1/2 left-[-50%] w-[200%] h-[3px] bg-red-500 rotate-[-15deg]"></span>
          </div>
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "12px", md: "14px" },
            fontFamily: "Blinker",
            color: "#10F9B1",
            marginBottom: { xs: "10px", md: "20px" },
          }}
        >
          Special Launch Price:
        </Typography>
        <Box
          sx={{
            border: "1px solid #10F9B1",
            borderRadius: "8px",
            height: { xs: "40px", md: "70px" },
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "18px", md: "28px" },
              fontFamily: "Blinker",
              fontWeight:"600"
            }}
          >
            ${discountPrice}
          </Typography>
        </Box>
        <Button
          variant="contained"
          href="/meeting"
          sx={{
            fontFamily: "blinker",
            fontWeight: "400",
            fontSize: "16px",
            marginTop: "10px",
            borderRadius: "8px",
          }}
          className="btn-header w-full bg-[#10F9B1] rounded-lg text-black"
        >
          Book A Call
        </Button>
      </Box>
      
    </Box>
  );
}
