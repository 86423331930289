import React, { useRef, useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import FreeConsultationBtn from "./FreeConsultationBtn";

const LastSectionVideo = ({ videoUrl, poster }) => {
  const videoRef = useRef(null);
  const [progress, setProgress] = useState(0); // State to manage the progress percentage
  
    const handleTimeUpdate = (e) => {
      if (e.target) {
        const currentTime = e.target.currentTime;
        const duration =  e.target.duration;
        const progressPercentage = (currentTime / duration) * 100;
        setProgress(progressPercentage);
      }
    };
    const handleEndVideo = (e) => {
      console.log(e)
      e.target.load()
      // e.loaded = false
    }
  const handleVideoClick = (e) => {
    // console.log(e.target.play())


    if(e.target.paused){
      console.log(e.target.play())

    }
    else{
      console.log(e.target.pause())
    }
  };

  return (
    <>
      <Box
        sx={{
          display:{lg : 'flex' , xs : 'none'},
          overflow: "hidden",
          flexDirection:'row-reverse',
          height: { md: "100vh", xs: "100vh" },
          // width: "100vw",
          // display: { md: "flex", xs: "flex" },
          alignItems: "center",
          justifyContent: "space-evenly",
        
          mb: { xs: 15, md: 0 },
          mx: { xl: "50px" , lg : '50px', md : '10px'},
          backgroundImage:''
        }}
      >
        <div className="">
          <div>
          {/* <Typography
              sx={{
                color: "#10F9B1",
                fontFamily: "blinker",
                fontSize: "57px",
                fontWeight: "700",
                // mb:2
              }}
            >
              Beauty in
            </Typography>
            <Typography
              sx={{
                color: "#10F9B1",
                fontFamily: "blinker",
                fontSize: "41px",
                fontWeight: "600",
                lineHeight: "10px",
                mb:4
              }}
            >
               Functionality
            </Typography> */}
            <h2 style={{ color: "#10F9B1", fontFamily: "blinker", fontSize: "57px", fontWeight: "600", lineHeight: "40px" ,marginBottom:"20px"}}>
                Beauty in
              <br />
              <span style={{ fontSize: "41px", fontWeight: "800" }}>
                Functionality
              </span>
            </h2>

            <Typography
              sx={{
                color: "#fff",
                fontFamily: "blinker",
                fontSize: {xl:"24px",lg: '19px'},
                fontWeight: "100",
                width:{xl:"267px",lg:'220px'}
              }}
            >
              User interfaces that are simple yet effective, designed with precision and detail
            </Typography>
          </div>
          <div className="mt-20">
            <FreeConsultationBtn text={'I Need Similar'}/>
          </div>
        </div>
        <div className="flex flex-col relative">
        <video
          src={videoUrl}
            // controls
          preload="none"
          ref={videoRef}
          onClick={handleVideoClick}
          onEnded={handleEndVideo}
          onTimeUpdate={handleTimeUpdate}
          type="video/webm"
          className="videoSection"
          style={{
            // width: "85%",
            height: "490px", // Maintain aspect ratio
            maxWidth: "875px",
            objectFit: "fill", // Prevent cropping of the video
            // boxShadow: "1px 1px 10px  #10F9B170", // Glow effect around the video
            // borderRadius: "10px", // Slightly rounded corners
          }}
          poster={poster}
        >
          {/* <source src=""></source> */}
        </video>
        <div
          style={{
            position: 'absolute',
            // mt:20,
            bottom:'-10px',
            // left: 0,
            height: '1%',
            width: `${progress}%`,
            backgroundColor: '#10F9B1',
            borderRadius: '5px',
            transition: 'width 0.1s linear',
          }}
        ></div>
        </div>
      </Box>
{/* ---------------------start---------------------------- */}
      <Box
        sx={{
          "@media (min-width: 1024px) and (max-width: 1199px)": { 
              display:'flex',
              },
          display:'none',
          overflow: "hidden",
          flexDirection:'row-reverse',
          height: { md: "100vh", xs: "100vh" },
          // width: "100vw",

          // display: { md: "flex", xs: "flex" },
          alignItems: "center",
          justifyContent: "space-evenly",
        
          mb: { xs: 15, md: 0 },
          px:"20px",
          backgroundImage:''
        }}
      >
        <div className=" pl-[4vw] max-w-[320px]">
          <div>
          {/* <Typography
              sx={{
                color: "#10F9B1",
                fontFamily: "blinker",
                fontSize: "57px",
                fontWeight: "700",
                // mb:2
              }}
            >
              Beauty in
            </Typography>
            <Typography
              sx={{
                color: "#10F9B1",
                fontFamily: "blinker",
                fontSize: "41px",
                fontWeight: "600",
                lineHeight: "10px",
                mb:4
              }}
            >
               Functionality
              
            </Typography> */}
            <h2 style={{ color: "#10F9B1", fontFamily: "blinker", fontSize: "57px", fontWeight: "600", lineHeight: "40px" ,marginBottom:"20px"}}>
                Beauty in
              <br />
              <span style={{ fontSize: "41px", fontWeight: "800" }}>
                Functionality
              </span>
            </h2>

            <Typography
              sx={{
                color: "#fff",
                fontFamily: "blinker",
                fontSize: {xl:"24px",lg: '19px'},
                fontWeight: "100",
                width:{xl:"267px",lg:'220px'}
              }}
            >
              User interfaces that are simple yet effective, designed with precision and detail
            </Typography>
          </div>
          <div className="mt-20">
            <FreeConsultationBtn text={'I Need Similar'}/>
          </div>
        </div>
        <div className="flex flex-col relative">
        <video
          src={videoUrl}
            // controls
          preload="none"
          ref={videoRef}
          onClick={handleVideoClick}
          onEnded={handleEndVideo}
          onTimeUpdate={handleTimeUpdate}
          type="video/webm"
          className="videoSection"
          style={{
            // width: "85%",
            height: "392px", // Maintain aspect ratio
            maxWidth: "700px",
            objectFit: "fill", // Prevent cropping of the video
            // boxShadow: "1px 1px 10px  #10F9B170", // Glow effect around the video
            // borderRadius: "10px", // Slightly rounded corners
          }}
          poster={poster}
        >
          {/* <source src=""></source> */}
        </video>
        <div
          style={{
            position: 'absolute',
            // mt:20,
            bottom:'-10px',
            // left: 0,
            height: '1%',
            width: `${progress}%`,
            backgroundColor: '#10F9B1',
            borderRadius: '5px',
            transition: 'width 0.1s linear',
          }}
        ></div>
        </div>
      </Box>



{/* ---------------------end---------------------------- */}
      <Box
        sx={{
          "@media (min-width: 1024px)": { 
              display:'none',
              },
          display:'flex',
          overflow: "hidden",
          height: { lg: "0", xs: "80vh" },
          flexDirection:'column',
          width: "90%",
          // display: { md: "flex", xs: "flex" },
          alignItems: "center",
          justifyContent: "center",
        
          // mb: { xs: 15, md: 0 },
          mx: { xs: "20px" },
          backgroundImage:''
        }}
      >
        <div className="">
          <div>
          {/* <Typography
              sx={{
                color: "#10F9B1",
                fontFamily: "blinker",
                fontSize: "57px",
                fontWeight: "700",
                // mb:2
              }}
            >
              Beauty in
            </Typography>
            <Typography
              sx={{
                color: "#10F9B1",
                fontFamily: "blinker",
                fontSize: "41px",
                fontWeight: "600",
                lineHeight: "10px",
                mb:4
              }}
            >
               Functionality
              
            </Typography> */}
            <h2 style={{ color: "#10F9B1", fontFamily: "blinker", fontSize: "57px", fontWeight: "600", lineHeight: "40px" ,marginBottom:"20px"}}>
                Beauty in
              <br />
              <span style={{ fontSize: "41px", fontWeight: "800" }}>
                Functionality
              </span>
            </h2>
            

            
          </div>
          
        </div>
        <div className="relative">
        <video
          src={'video/LastSection.webm'}
          //   controls
          preload="none"
          ref={videoRef}
          onClick={handleVideoClick}
          onEnded={handleEndVideo}
          onTimeUpdate={handleTimeUpdate}
          type="video/webm"
          style={{
            // width: "85%",
            height: "182px", // Maintain aspect ratio
            maxWidth: "324px",
            objectFit: "fill", // Prevent cropping of the video
            // boxShadow: "1px 1px 10px  #10F9B170", // Glow effect around the video
            // borderRadius: "10px", // Slightly rounded corners
          }}
          className="videoSection"
          poster={'video/LastSection.png'}
        >
          {/* <source src=""></source> */}
        </video>
        <div
          style={{
            position: 'absolute',
            // mt:20,
            bottom:'-10px',
            // left: 0,
            height: '1%',
            width: `${progress}%`,
            backgroundColor: '#10F9B1',
            borderRadius: '5px',
            transition: 'width 0.1s linear',
          }}
        ></div>
        </div>
        <Typography
              sx={{
                color: "#fff",
                fontFamily: "blinker",
                fontSize: "15px",
                fontWeight: "100",
                width: "267px",
                mt:4,
                
              }}
            >
              User interfaces that are simple yet effective, designed with precision and detail
            </Typography>
        <div className="mt-10">
            <FreeConsultationBtn text={'I Need Similar'}/>
          </div>
      </Box>
    </>
  );
};

export default LastSectionVideo;
