import React from 'react'
import AdsHeroSection from './AdsHeroSection'
import AdsSectionTwo from './AdsSectionTwo'
import AdsSectionThree from './AdsSectionThree'
import AdsSectionFour from './AdsSectionFour'
import AdsSectionFive from './AdsSectionFive'
import AdsEndSection from './AdsEndSection'
import {Helmet} from "react-helmet";


function AdsPage() {
  return (
    <div className='text-white mb-[100px] overflow-hidden'>
      <Helmet>
    <title>Digital Advertising in Canada | Google Ads, Social Media & PPC</title>
    <meta name="description" content="Maximize your online reach with Beverly Digital’s advertising services. We specialize in Google Ads, social media ads, and PPC campaigns tailored to your business." />
    <meta name="keywords" content="Digital advertising Canada, Google Ads agency, Social media ads Toronto, PPC management services, Facebook Ads specialists, Instagram advertising, Local business advertising, Paid search marketing, Online ad strategy, YouTube Ads experts" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
</Helmet>
      <AdsHeroSection />
      <AdsSectionTwo />
      <div className='relative seo-sec2-background-2' >
        <div className='sticky top-0'>
      <AdsSectionThree />
      <AdsSectionFour />
      <AdsSectionFive />
      </div>
      </div>
      <AdsEndSection />
    </div>
  )
}

export default AdsPage
