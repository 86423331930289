import React from 'react'
import ContentHeroSection from './ContentHeroSection'
import ContentSectionTwo from './ContentSectionTwo'
import ContentSectionThree from './ContentSectionThree'
import ContentSectionFour from './ContentSectionFour'
import ContentEndSection from './ContentEndSection'
import ContentSectionFive from './ContentSectionFive'
import {Helmet} from "react-helmet";


function ContentDesignPage() {
  return (
    <div className='text-white mb-[100px] overflow-hidden'>
      <Helmet>
    <title>Content Creation Services | Social Media, SEO Content & Video</title>
    <meta name="description" content="Engaging content drives business growth. Beverly Digital creates SEO-friendly blog posts, social media content, video marketing, and compelling brand storytelling." />
    <meta name="keywords" content="Content creation Canada, SEO content writing, Social media content Toronto, Video marketing services, Blog writing agency, Copywriting for small businesses, Brand storytelling, Creative content marketing, Business content strategy, Multimedia content production" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
</Helmet>
      <ContentHeroSection />
      <ContentSectionTwo />
      <div className='relative seo-sec2-background-2 ' >
        <div className='sticky top-0'>
    <ContentSectionThree />
    <ContentSectionFour />
    <ContentSectionFive />

      </div></div>
      <ContentEndSection />
    </div>
  )
}

export default ContentDesignPage
