import { Button } from '@mui/material';
import React from 'react';

export default function SmallBtn({ text, href }) {
  return (
    <Button 
      variant="contained"
      href={href || "/meeting"} 
      sx={{
        fontFamily: 'blinker',
        fontWeight: '400',
        textTransform: 'none',
        fontSize: { xl: '13px', md: '15px' },
        // justifyContent:"center",
      }}
      className="btn-header lg:w-[170px] w-[126px] h-[32px] bg-[#10F9B1] rounded-lg text-black mt-4 "
    >
      {text || "Free Consultation"}
    </Button>
  );
}
