import React, { useEffect, useRef, useState } from "react";
// import SectionOne from "./SectionOne";
import '../../Library/css/dotsHome.css';
// import SectionService from "./SectionService";

import { Suspense } from 'react';
// import HeroSection from "./HeroSection";
import CircleLoader from "../circleLoader";
import { Helmet } from "react-helmet";
import useMedia from '../../hooks/useMedia';



// const About = React.lazy(() => import('./Components/About'));
// import SectionFAQ from "./SectionFAQ";
// import SectionFour from "./SectionFour";
import MobileFullscreenVideo from "../videoComponent";
import MobileHeroSection from "./HeroSection";
import MobileSectionOne from "./SectionOne";
import MobileSectionService from "./SectionService";
import MobileSectionFAQ from "./SectionFAQ";
import MobileSectionFour from "./SectionFour";
import Footer from "../mainFooter";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import LastSectionVideo from "../LastSectionVideo";
import Footer1 from "./Footer1";
import Footer2 from "./Footer2";
import Footer3 from "./Footer3";
const Home = () => {
  const [lockScroll, setLockScroll] = useState(false);
  const HeroSection = React.lazy(() => import('./HeroSection'));
  const SectionOne = React.lazy(() => import('./SectionOne'));
  const SectionFAQ = React.lazy(() => import('./SectionFAQ'));
  const SectionFour = React.lazy(() => import('./SectionFour'));
  const SectionService = React.lazy(() => import('./SectionService'));
    // const Footer = React.lazy(() => import('../../Components/mainFooter'));
  // const FullscreenVideo = React.lazy(() => import('../videoComponent'));
  const { lg } = useMedia()
  const swiperRef = useRef(null)
  
  const changedSwiper = (e) => {
    // console.log(e)
    // if(e.activeIndex === 1 || e.activeIndex === 5)
    //   e.enabled = false
  }

  useEffect(() => {
    // Lock or unlock parent scroll
    document.body.style.overflow = lockScroll ? "hidden" : "auto";
  }, [lockScroll]);

  useEffect
  (() => {
    console.log(swiperRef)
  }, [swiperRef]);
// const swiper = useSwiper()
  const handleScrollStatusChange = (isLocked) => {
    setLockScroll(isLocked);

    // console.log(isLocked)
    if(isLocked)
      swiperRef.current.enabled = false
    else{
      swiperRef.current.enabled = true;
    }
    // swiperRef.arguments.enabled = false
    
  };
  return (
    <>
      {lg ? (
        <div className="bg-dark text-white">
          <Helmet>
          <title>Beverly Digital | Branding & Creative Marketing Solutions in Canada</title>
        <meta name="description" content="Boost your business with Beverly Digital’s expert branding, creative content, web design, SEO, and digital marketing solutions. Helping small businesses in Canada stand out and grow." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="keywords" content="Branding services Canada, Creative marketing agency, Digital marketing for small businesses, Web design and SEO Toronto, Local SEO solutions Canada, Content marketing agency Toronto, Small business growth strategies, Logo and brand identity design, Social media marketing Canada, Google Ads for local businesses, Best branding agency for startups in Canada, How to grow a small business with digital marketing, Affordable web design for small businesses in Toronto, Expert SEO strategies for Canadian businesses, Top creative marketing solutions for local businesses"/>
          </Helmet>
          <>
            <Suspense fallback={<CircleLoader isVisible={true} />}>

              
              <HeroSection /></Suspense>
            <Suspense fallback={<span>Loading animation...</span>}>

              <SectionOne />
            </Suspense>
            <Suspense fallback={<span>Loading animation...</span>}>
              {/* <div className="overflow-scroll"> */}

              <SectionService />
              {/* </div> */}

            </Suspense>
            {/* <Suspense fallback={<span>Loading animation...</span>}> */}
              <MobileFullscreenVideo videoUrl='video/ThirdSection.mp4' poster='video/ThirdSection.png' />
              {/* </Suspense> */}
            <Suspense fallback={<span>Loading animation...</span>}>
              {/* <div className=""></div> */}
              <SectionFour />
              </Suspense>
              <LastSectionVideo videoUrl='video/LastSection.webm' poster="video/LastSection.png" />
            <Suspense fallback={<span>Loading animation...</span>}>
              {/* <div className=""></div> */}
              <SectionFAQ />
              </Suspense>
            {/* <Suspense fallback={<span>Loading animation...</span>}> */}
              {/* <div className=""></div> */}
              
              {/* </Suspense> */}

              {/* <div className=""></div> */}
              <Footer />
          </>
        </div>) :
        (

          <Swiper

          onSwiper={(swiper) => (swiperRef.current = swiper)}
            direction={'vertical'}
            onSlideChange={changedSwiper}
            slidesPerView={1}
            // mousewheel={{ forceToAxis: true }}
            style={{ height: "90dvh", width: "100%", background: "transparent", display:'flex' , flexDirection:'column' , alignItems:'center' , justifyItems:'center'  }}
            pagination={{
              clickable: true,
            }}
            
          >
            <SwiperSlide className="swiper-slide-home">
            <MobileHeroSection />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide-home">
            <MobileSectionOne onScrollStatusChange={handleScrollStatusChange}/>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide-home">
            <MobileSectionService />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide-home">
            <MobileFullscreenVideo videoUrl='video/ThirdSection.mp4' poster='video/ThirdSection.png' />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide-home">
            <MobileSectionFour />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide-home">
            <LastSectionVideo  />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide-home">
            <MobileSectionFAQ onScrollStatusChange={handleScrollStatusChange}/>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide-home">
            <Footer1  />
            </SwiperSlide>
            
            <SwiperSlide className="swiper-slide-home">
            <Footer3  />
            </SwiperSlide>
            
            
            {/* <SwiperSlide>
              <Footer />
            </SwiperSlide> */}
          </Swiper>

        )
      }
    </>
  );
};

export default Home;
