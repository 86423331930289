import React, { useState } from "react";
import "../Library/css/chatBtn.css"; // Import the CSS file
import { Typography } from "@mui/material";
import tel from './SVG/telegram.png'
import whats from './SVG/whatsapp.png'
const ContactBtn = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleCloseModal = (event) => {
    // Close modal if clicking outside the modal box
    if (event.target.classList.contains("modal-overlay")) {
      setIsModalOpen(false);
    }
  };

  const handleTelegramClick = () => {
    window.open("https://t.me/beverlysupport", "_blank");
  };

  const handleWhatsAppClick = () => {
    window.open("https://wa.me/+447388569728", "_blank");
  };

  return (
    <div className="chatbot-container">
      {/* Floating Button */}
      <div className="float-button ease duration-300 rotate-180" onClick={toggleModal}>
        {isModalOpen ? (<span>-</span>) : (<span>+</span>)}
      </div>

      {/* Modal at Bottom Right */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal">
            <Typography sx={{ color: "#10F9B1", textAlign: "center" }}>
              Support Hub
            </Typography>
            <div className="modal-option" onClick={handleTelegramClick}>
              <img width="20px" height="20px" style={{
                marginLeft:'10px'
              }} src={tel} alt="Telegram" />
              Telegram
            </div>
            <div className="modal-option" onClick={handleWhatsAppClick}>
              <img width="20px" height="20px" style={{
                marginLeft:'16px'
              }} src={whats} alt="WhatsApp" />
              WhatsApp
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactBtn;
