
import { Box, Button, Typography } from "@mui/material";
import React from 'react';
import SmallBtn from "../SmallBtn";
import LazyImage from '../lazyImage';
import { List, ListItem } from "@mui/material";

export default function AdsCampaignSection3({ 
}) {
  return (
    <Box
      sx={{
        maxWidth:"1440px",
        display:"flex",
        // justifyContent:{xs:"center",md:"space-around"},
        alignItems:"center",
        flexDirection:{xs:"column", md:"row"},
        
        // display:"flex",
        // justifyContent:"center",
        // justifyItems:"center",
        // alignContent:"center",
        // alignItems:"center",
        // backgroundColor:"red"

      }}
      // className="flex justify-center items-center"
    >
      <Typography
          sx={{
            fontSize: { xs: "24px", md: "40px" },
            fontWeight: "600",
            display:{xs:"flex",md:"none"},
            fontFamily: "Blinker",
            maxWidth:"200px",
            marginBottom:"30px"
          }}
        >
           Why Choose These Packages?
        </Typography>
      <LazyImage
        src="./adssec3.png"
        clss="w-[250px] h-[200px] lg:w-[520px] lg:h-[400px] mb-4 "
        // alt="Search Icon"
      />
      <Box sx={{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        // justifyContent:"end",
        marginLeft:{xs:"0", md:"60px"},
        marginTop:{xs:"40px", md:"100px"},
      }}>
        <Typography
          sx={{
            fontSize: { xs: "24px", md: "40px" },
            fontWeight: "600",
            display:{xs:"none",md:"flex"},
            fontFamily: "Blinker",
          }}
        >
           Why Choose These Packages?
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "14px", md:"16px" },
            fontWeight: "400",
            fontFamily: "Blinker",
            display: "flex",
            alignItems: "center",
            marginLeft:{ xs: "30px",md:"50px"},
            "&::before": {
              content: '"•"',
              marginRight: "8px",
              color: "primary.main", 
            },
          }}
        >
          More traffic = more sales.
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "14px", md:"16px" },
            fontWeight: "400",
            fontFamily: "Blinker",
            display: "flex",
            alignItems: "center",
            marginLeft:{ xs: "30px",md:"50px"},
            "&::before": {
              content: '"•"',
              marginRight: "8px",
              color: "primary.main", 
            },
          }}
        >
          More visibility = more traffic.
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "14px", md:"16px" },
            fontWeight: "400",
            fontFamily: "Blinker",
            display: {xs:"none",md:"flex"},
            alignItems: "center",
            marginLeft:{ xs: "30px",md:"50px"},
            // maxWidth:"450px",
            "&::before": {
              content: '"•"',
              marginRight: "8px",
              color: "primary.main", 
            },
          }}
        >
          Your ads appear where your customers are looking—on
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "14px", md:"16px" },
            fontWeight: "400",
            fontFamily: "Blinker",
            display: {xs:"none",md:"flex"},
            alignItems: "center",
            marginLeft:{ xs: "45px",md:"65px"},
          }}
        >
           search engines, social media, and websites.
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "14px", md:"16px" },
            fontWeight: "400",
            fontFamily: "Blinker",
            display: {xs:"flex",md:"none"},
            alignItems: "center",
            marginLeft:{ xs: "30px",md:"50px"},
            // maxWidth:"450px",
            "&::before": {
              content: '"•"',
              marginRight: "8px",
              color: "primary.main", 
            },
          }}
        >
          Your ads appear where your customers
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "14px", md:"16px" },
            fontWeight: "400",
            fontFamily: "Blinker",
            display: {xs:"flex",md:"none"},
            alignItems: "center",
            marginLeft:"45px",
            maxWidth:"200px"
          }}
        >
           are looking—on search engines, social media, and websites.
        </Typography>
        <Box sx={{
          display:"flex",
          justifyContent:{xs:"center", md:"left"},
        }}>
        <Button 
          // variant="contained"
          href="/meeting"
          sx={{
            fontFamily: "Blinker",
            fontWeight: 400,
            // textTransform: "none",
            fontSize: "14px",
            border: "1px solid #10F9B1",
            borderRadius: "20px",
            width: { lg: "150px", xs: "140px" },
            padding:"20px",
            height: "32px",
            mt: 4,
          }}
        >
          Let’s connect
        </Button>
        </Box>
      </Box>


      
    </Box>
  );
}
