// import { Box, Button, Typography, Modal } from '@mui/material'
// import React from 'react'

// import CloseIcon from '@mui/icons-material/Close';
// // import { useLocation } from "react-router-dom";
// import { useNavigate } from 'react-router-dom';


// export default function ModalTesting({openModal}) {
//   const router = useNavigate()
//   const handleClose = () => {
//     router(-1)
//   };
  
//   return (
    
//     // <div  className="flex items-center justify-center h-screen" >
//       <div>
//       <Modal
//         open={openModal}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//         sx={{
//           display: 'flex !important',
//           justifyContent: 'center !important',
//           alignItems: 'center !important',
//           height: '100vh !important', 
//           padding:"10px !important"
//         }}
//       >
//         <Box sx={{
//           // position: 'absolute',
//           // top: { xs: '100px', md: '10vw'  },
//           // left: { xs: '41px', md: '20vh', lg:'50vh', xl:'50vh'   },
//           fontFamily: 'blinker',
//           textAlign:'center',
//           background: 'rgba(120, 141, 232, 1)',
//           "@media (max-width: 1023px)":{
//             width:"355px",
//             height:"550px",
//               },
//           "@media (min-width: 1024px) and (max-width: 1439px)":{
//             width:"680px",
//             height:"500px",
//               },
//           "@media (min-width: 1440px)": { 
//             width:"890px",
//             height:"615px",
//               },
//           // width: { xs: '310px', md: '887px', lg:'887px', xl:'887px' },
//           // height: { xs: '584px', md: '613px', lg:'613px', xl:'613px' },
//           borderRadius: 8,
//           marginTop:"50px"


//         }}>
//           <Button sx={{
//             // position: 'absolute',
//             // top: 15,
//             // right: 15,
//             color:'white',
//             display: 'flex !important',
//             // textAlign:"right !important",
//             // justifyItems:"end"
//           }} 
//           size="large" onClick={handleClose}>
//             <CloseIcon fontSize='large'  />
//           </Button>
//           <Typography id="modal-modal-title " className="text-white text-6xl   "  sx={{ 
//             "@media (max-width: 1023px)":{
//               mt:"0px" ,
//               fontSize:'38px',
//                 },
//             "@media (min-width: 1024px) and (max-width: 1439px)":{
//               mt:"20px" ,
//               fontSize:'46px',
//                 },
//             "@media (min-width: 1440px)": { 
//               mt:"30px" ,
//               fontSize:'64px',
//                 },
//             fontFamily: 'blinker', 
//             fontWeight:'700'}}>
//           Thanks for connecting!
//           </Typography>
//           <Typography id="modal-modal-description " className="text-stone-900 text-xl font-bold py-5 mx-15" sx={{ 
//           fontSize:{ xs:'32px'}, 
//           fontFamily: 'Blinker', 
//           background: "linear-gradient(37deg, rgba(159,253,244,0.01) 0%, rgba(49,230,174,1) 10%, rgba(208,241,231,1) 50%, rgba(27,228,166,1) 100%)",
//           padding: '20px', 
//           mt:{ xs: '20px', md: '40px' },
//           // mx:{ xs: '56px', md: '56px' },
//           "@media (min-width: 1024px) and (max-width: 1439px)":{
//               width:"490px" ,
//               marginLeft:"100px",
//               fontSize:'32px',
//                 },
//             "@media (min-width: 1440px)": { 
//               width:"644px" ,
//               marginLeft:"120px",
//               fontSize:'41px',
//                 },
//           textAlign: 'center',
//           borderRadius: '40px', 
//         }}>
//           Welcome to 
//           <strong sx={{
//             fontSize: { xs: '28px'},
//                  }}> BEVERLY!</strong> 
//           </Typography>
//           <Typography id="modal-modal-description " sx={{
//             fontFamily: 'blinker',
//             mt:{ xs: '30px', md: '40px' },
//             mx:{ xs: '56px', md: '56px' },
//             "@media (max-width: 1023px)":{
//               fontSize:'20px',
//                 },
//             "@media (min-width: 1024px) and (max-width: 1439px)":{
//               fontSize:'24px',
//                 },
//             "@media (min-width: 1440px)": { 
//               fontSize:'38px',
//                 },
//                }}>
//           We’ d love for you to get to know us
//           </Typography>
//           <Typography id="modal-modal-description " sx={{ 
//             mt:{ xs: '40px', md: '40px' } ,
//             fontFamily: 'blinker',
//             mx:{ xs: '56px', md: '56px' },
//             "@media (max-width: 1023px)":{
//                 fontSize:'13px',
//                   },
//               "@media (min-width: 1024px) and (max-width: 1439px)":{
//                 fontSize:'20px',
//                   },
//               "@media (min-width: 1440px)": { 
//                 fontSize:'24px',
//                   },
//             }}>
//           Your information is being reviewed. We'll contact you within 3 business days
//           </Typography>
//         </Box>
//       </Modal>
//     </div>
//   )
// }

import { Box, Button, Typography, Modal } from '@mui/material'
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';


export default function ModalTesting({openModal , name , formattedDate }) {
  const router = useNavigate()
  const handleClose = () => {
    router(-1)
  };
  
  return (
    
    // <div  className="flex items-center justify-center h-screen" >
      <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex !important',
          justifyContent: 'center !important',
          alignItems: 'center !important',
          height: '100vh !important', 
          padding:"10px !important"
        }}
      >
        <Box sx={{
          // position: 'absolute',
          // top: { xs: '100px', md: '10vw'  },
          // left: { xs: '41px', md: '20vh', lg:'50vh', xl:'50vh'   },
          fontFamily: 'blinker',
          textAlign:'center',
          background: 'rgba(120, 141, 232, 1)',
          "@media (max-width: 1023px)":{
            width:"355px",
            height:"550px",
              },
          "@media (min-width: 1024px) and (max-width: 1439px)":{
            width:"680px",
            height:"500px",
              },
          "@media (min-width: 1440px)": { 
            width:"890px",
            height:"615px",
              },
          // width: { xs: '310px', md: '887px', lg:'887px', xl:'887px' },
          // height: { xs: '584px', md: '613px', lg:'613px', xl:'613px' },
          borderRadius: 8,
          marginTop:"50px",
          
          justifyItems:"center",
          // textJustify:"center !important",
          // alignItems:"center !important",
          // justifyContent:"center !important",
          // backgroundColor:"red !important"


        }}>
          <Button sx={{
            // position: 'absolute',
            // top: "215px",
            // left: "175px",
            color:'white',
            display: 'flex !important',
            paddingRight:"300px",
            mt:"10px",
            "@media (min-width: 1024px) and (max-width: 1439px)":{
                  paddingRight:"620px",
                  mt:"10px"
                  },
            "@media (min-width: 1440px)": { 
                  paddingRight:"820px",
                  mt:"20px"
                  },
          }} 
          size="large" onClick={handleClose}>
            <CloseIcon fontSize='large'  />
          </Button>
          <Typography id="modal-modal-title " className="text-white text-6xl   "  sx={{ 
            "@media (max-width: 1023px)":{
              mt:"0px" ,
              fontSize:'25px',
              width:"270px",
                },
            "@media (min-width: 1024px) and (max-width: 1439px)":{
              mt:"20px" ,
              fontSize:'33px',
                },
            "@media (min-width: 1440px)": { 
              mt:"30px" ,
              fontSize:'43px',
                },
            fontFamily: 'blinker', 
            fontWeight:'700'}}>
          Your First Step with Beverly Starts Here!
          </Typography>
          <Typography id="modal-modal-description " className="text-stone-900 text-xl font-bold" sx={{ 
          fontSize:{ xs:'32px'}, 
          fontFamily: 'Blinker', 
          background: "linear-gradient(37deg, rgba(159,253,244,0.01) 0%, rgba(49,230,174,1) 10%, rgba(208,241,231,1) 50%, rgba(27,228,166,1) 100%)",
          padding: '20px', 
          mt:{ xs: '20px', md: '40px' },
          "@media (max-width: 1023px)":{
              width:"270px",
                },
          "@media (min-width: 1024px) and (max-width: 1439px)":{
              width:"520px" ,
              // marginLeft:"100px",
              fontSize:'30px',
                },
          "@media (min-width: 1440px)": { 
              width:"700px" ,
              // marginLeft:"120px",
              fontSize:'38px',
                },
          // "@media (min-width: 1024px) and (max-width: 1439px)":{
          //     width:"490px" ,
          //     marginLeft:"100px",
          //     fontSize:'30px',
          //       },
          // "@media (min-width: 1440px)": { 
          //     width:"644px" ,
          //     marginLeft:"120px",
          //     fontSize:'38px',
          //       },
          textAlign: 'center',
          borderRadius: '40px', 
        }}>
          Welcome to 
          <strong> BEVERLY {name} ! </strong> 
          </Typography>
          <Typography id="modal-modal-description " sx={{
            // fontFamily: 'blinker',
            fontFamily:"blinker",
            mt:{ xs: '30px', md: '40px' },
            mx:{ xs: '53px', md: '56px' },
            px:{ xs: '0px', md: '70px' },
            // maxWidth:"400px",

            "@media (max-width: 1023px)":{
              fontSize:'20px',
                },
            "@media (min-width: 1024px) and (max-width: 1439px)":{
              fontSize:'20px',
                },
            "@media (min-width: 1440px)": { 
              fontSize:'30px',
                },
               }}>
          Your meeting is scheduled for <strong >{formattedDate}</strong>. The meeting link will be sent to your email shortly.
          </Typography>
          <Typography id="modal-modal-description " sx={{ 
            mt:{ xs: '30px', md: '20px' } ,
            fontFamily:"blinker",
            mx:{ xs: '60px', md: '56px' },
            "@media (max-width: 1023px)":{
                fontSize:'13px',
                  },
              "@media (min-width: 1024px) and (max-width: 1439px)":{
                fontSize:'16px',
                  },
              "@media (min-width: 1440px)": { 
                fontSize:'24px',
                  },
            }}>
          We can’t wait to meet you and start this journey together!
          </Typography>
        </Box>
      </Modal>
    </div>
  )
}