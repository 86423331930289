
import { Box, Button, Typography , TextField } from "@mui/material";
import SmallBtn from "../SmallBtn";
import LazyImage from '../lazyImage';
import { List, ListItem } from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import axios from 'axios';

export default function AdsCampaignSection4() {
    const [email, setEmail] = useState('');

    //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //   if (!email) {
    //       newErrors.email = 'Email is required';
    //   } else if (!emailRegex.test(email)) {
    //       newErrors.email = 'Invalid email format';
    //   }
    //   if (Object.keys(newErrors).length > 0) {
    //       setErrors(newErrors);
    //       return;
    //   }
    //   try {
    //       setErrors({});
    //       console.log(date)
    //       axios.post(apiUrl + '/adscampaign', {
    //           email,

    //       });
          
    //   } catch (error) {
    //       alert('Error submitting form');
    //   }
  return (
    <Box
      sx={{
        maxWidth:"1440px",
        display:"flex",
        alignItems:"center",
        // flexDirection:{xs:"row", md:"column"},
        flexDirection:"column",
        padding:"90px 0",

        // backgroundImage:{xs:`url("../seocamp2.png")`, md:`url("../seocamp.png")`},
        backgroundImage:`url("../adsSec4.png")`,
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: "contain",
      }}
    >
        <Typography
          sx={{
            fontSize: { xs: "24px", md: "40px" },
            fontWeight: "600",
            fontFamily: "Blinker",

          }}
        >
            Why Act Now?
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "18px", md: "33px" },
            fontWeight: "500",
            // display:{xs:"flex",md:"none"},
            fontFamily: "Blinker",
            color:"#10F9B1"
          }}
        >
            Let’s Make 2025 Your Best Year Yet!
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "14px", md: "16px" },
            fontWeight: "300",
            // display:{xs:"flex",md:"none"},
            fontFamily: "Blinker",
            marginTop:"20px",
            marginBottom:"50px",
            textAlign:"center",            // "@media (min-width: 1024px) and (max-width: 1439px)":{},
            "@media(max-width: 1023px)":{
                maxWidth:"270px"
            },
          }}
        >
            Don’t miss out on this chance to grow your business and achieve success this year.
        </Typography>
      <Box sx={{
        display:"flex",
        flexDirection:{xs:"column", md:"row"},
        padding:"50px",
        marginTop:{md:"50px"},
      }}>
        <Box sx={{
            border: "1px solid #A1AEBF",
            // padding:{xs:"20px 100px 20px 20px" ,md:"20px 250px 20px 20px"},
            padding:"20px 20px 20px 20px",
            width:{xs:"80vw", md:"30vw"}, 
            maxWidth:"400px",
            marginRight:{md:"20px"},

        }}>
            <LazyImage
                src="./cash-coin.png"
                clss="w-[41px] h-[41px] "
            />
            <Typography
                sx={{
                    fontSize: { xs: "19px", md: "28px" },
                    fontWeight: "500",
                    // display:{xs:"flex",md:"none"},
                    fontFamily: "Blinker",
                    marginTop:"10px",
                }}
                >
                    Exclusive Offers
                </Typography>
                <Typography
                sx={{
                    fontSize: { xs: "12px", md: "14px" },
                    fontWeight: "300",
                    // display:{xs:"flex",md:"none"},
                    fontFamily: "Blinker",
                    // marginTop:"20px"
                }}
                >
                    Act fast and save up to 50%.
                </Typography>
        </Box>
        <Box sx={{
            border: "1px solid #A1AEBF",
            // padding:{xs:"20px 100px 20px 20px" ,md:"20px 250px 20px 20px"},
            padding:"20px 20px 20px 20px",
            width:{xs:"80vw", md:"30vw"}, 
            maxWidth:"400px",
            marginLeft:{md:"20px"},
            display:"flex",
            flexDirection:"column",
            marginTop:{xs:"20px", md:"0"}
            // alignItems:"center",
        }}>
            <LazyImage
                src="./alarm.png"
                clss="w-[41px] h-[41px] "
            />
            <Typography
                sx={{
                    fontSize: { xs: "19px", md: "28px" },
                    fontWeight: "500",
                    // display:{xs:"flex",md:"none"},
                    fontFamily: "Blinker",
                    marginTop:"10px"
                }}
                >
                    Limited Time Only
                </Typography>
                <Typography
                sx={{
                    fontSize: { xs: "12px", md: "14px" },
                    fontWeight: "300",
                    // display:{xs:"flex",md:"none"},
                    fontFamily: "Blinker",
                    // marginTop:"20px"
                }}
                >
                     Lock in these prices before they’re gone.
                </Typography>
        </Box>
      </Box>
                                             {/* part two */}
      <Box sx={{
        padding:"120px 50px",
        "@media (min-width: 1024px)":{
            // padding:"90px 200px",
            // maxWidth:"1440px",
            maxWidth:"1024px",
            width:"80vw",

        },
        display:"flex",
        alignItems:"center",
        backgroundColor:"#BC83F5",
        // flexDirection:{xs:"row", md:"column"},
        flexDirection:"column",
        borderRadius:"5px",
      }}>
        <Typography
            sx={{
                fontSize: { xs: "24px", md: "40px" },
                fontWeight: "600",
                // display:{xs:"flex",md:"none"},
                fontFamily: "Blinker",
                marginBottom:"20px",
                color:"black"
            }}
            >
                Let’s discuss the idea
        </Typography>
        <Box sx={{
            display:"flex",
            flexDirection:{xs:"column", md:"row"},
            alignItems:"center",
            "@media (max-width: 1023px)":{marginTop:"20px",},

        }}>
            <TextField type="email" id="calendarmode" required fullWidth  value={email} placeholder="Your email"
                onChange={(e) => setEmail(e.target.value)}
                // error={!!errors.email}
                // helperText={errors.email}
                InputProps={{
                    sx: {
                        '& .MuiInputBase-input': {
                            color:"black !important",
                        },
                    }
                }}
                inputProps={{ style: { backgroundColor: 'white', fontFamily: 'blinker', fontSize: '16px !important',borderRadius: "40px",
                     "@media (min-width: 1024px)":{width:"300px"},width:"230px",
                     } }}
            />

            <Button
                variant="contained"
                // onClick={SubmitData}
                sx={{
                    borderRadius: "40px !important",
                    marginLeft: "1vw",
                    "@media (max-width: 1023px)":{
                        marginTop:"20px",
                    },
                    color:"white",
                    backgroundColor:"black",
                    width:"100px !important",
                    minWidth:"100px !important",
                }}
            >
                SEND
            </Button>

        </Box>

      </Box>
      <Button
            variant="outlined"
            color="primary"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            sx={{
            borderRadius: "40px !Important",
            width: "120px",
            minWidth: "120px",
            "@media (min-width: 1024px)":{
                width: "160px",
                minWidth: "160px",
                padding:"10px 0"
            },
            marginTop:"40px"
            }}
        >
            Go to Top
        </Button>
      
    </Box>
  );
}
