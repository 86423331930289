
import { Box, Button, Grid, Typography } from "@mui/material";
import { styled } from '@mui/system';
import React from 'react';
import AdsCampaignSection1 from "./adsCampaignSection1";
import AdsCampaignSection2 from "./adsCampaignSection2";
import AdsCampaignSection3 from "./adsCampaignSection3";
import AdsCampaignSection4 from "./adsCampaignSection4";


const Image = styled('img')({});


const AdsCampaign = () => {


  return (
    <Box height={'auto'}>
      <AdsCampaignSection1/>
                       {/* section2 */}
      <Box 
        sx={{
            display:{md:"flex"},
            justifyContent:"center",
          }}
        >
      <AdsCampaignSection2
          title="Bring Traffic"
          titleimage="title1.png"
          description="Bring more visitors to your site and convert them into loyal customers."
          price="1,000"
          discountPrice="600"
          features={[
            "Targeted campaigns to drive visitors to your site",
            "Conversion-focused landing pages to turn visitors into loyal customers",
          ]}
          isPopular="Most Popular"
        />
        <AdsCampaignSection2
          title="Full Growth"
          titleimage="title2.png"
          description="Maximize results with combined visibility and traffic strategies."
          price="2,800"
          discountPrice="1,500"
          features={[
            "Full visibility and traffic campaigns combined",
            "Monthly performance reports to track your success",
          ]}
          isPopular="Best Value"
        />
        <AdsCampaignSection2
          title="Get Notice"
          titleimage="title3.png"
          description="Get noticed on the platforms that matter most."
          price="650"
          discountPrice="350"
          features={[
            " Ads on search engines, social media, and websites",
            "Boost your brand’s visibility and attract new customers",
          ]}
          isPopular="Basic"
        /></Box>
        <Box 
        sx={{
            display:{md:"flex"},
            // flexDirection:"column",
            justifyContent:"center",
            marginTop:"100px",
          }}
        >
        <AdsCampaignSection3/>
        
        </Box>
        <Box 
        sx={{
            display:{md:"flex"},
            // flexDirection:"column",
            justifyContent:"center",
            marginTop:"30px",
            backgroundColor:"black"
          }}
        >
        <AdsCampaignSection4/>
        
        </Box>
      </Box>
  );
};

export default AdsCampaign;
