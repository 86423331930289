import React, { useEffect, useState } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import '../Library/css/Navbar.css';
import LazyImage from './lazyImage';
import { Button } from '@mui/material';
import GetaProposalBtn from './GetaProposalBtn';
import logo from '../images/logo.png'
// import ScrollToTop from './scrollToTop';

const Image = styled('img')({});

function ResponsiveAppBar({ onLogoClick }) {
  window.scrollTo(0, 0);
  const [isOpen, setOpen] = useState(false);
  const scrollToTop1 = () => {
    window.scrollTo(0, 0);
    // console.log("first")
  }
  const toggleMenu = () => {
    setOpen(!isOpen);
    const newMenuState = !isOpen;
    document.body.style.overflow = newMenuState ? "hidden" : "scroll";
  };
  const location = useLocation();
  const navItems = [
    { name: 'Branding', path: '/services/branding' },
    { name: 'Web Design', path: '/services/web-design' },
    { name: 'Ads', path: '/services/ads' },
    { name: 'Content Creation', path: '/services/content-creation' },
    { name: 'SEO', path: '/services/seo' },
    { name: 'Case Study', path: '/case-study' },
    { name: 'Blog', path: '/blog' },
    { name: 'About Us', path: '/about' },
    { name: 'Contact Us', path: '/contact' },
    
   
    
  ];
  const [isHome , setIsHome] = useState()
  useEffect(() => {
      setIsHome(location.pathname === '/');
    }, [location.pathname]);
  return (
    <div className='overflow-hidden ' >
    <header className="bg-dark p-4 z-[100000000] w-full fixed  top-0 header-main">
      {/* Desktop Navbar */}
      <nav className="container mx-auto lg:flex justify-between  hidden header-desktop">
        <div className="header-logo">
         {isHome ? <Image
         onClick={
          () => scrollToTop1()
         }
            sx={{
              position: 'relative',
              zIndex: '100000',
              width: { md: '166px', xs: '92px' },
              height: { md: '43px', xs: '30px' },
            }}
            src={logo}
            alt="logo"
          />:(
          <NavLink to="/" onClick={onLogoClick}>
          <Image
            sx={{
              position: 'relative',
              zIndex: '100000',
              width: { md: '166px', xs: '92px' },
              height: { md: '43px', xs: '30px' },
            }}
            src={logo}
            alt="logo"
          />
          
        </NavLink>
         )} 
        </div>
        {/* {location.pathname} */}
        <div className="header-items flex justify-center items-center xl:gap-5 lg:gap-3">
          {navItems.map((item) => (
            <div className="header-item" key={item.path}>
              <NavLink
                to={item.path}
                className={location.pathname === item.path
                    ? 'isActiveNav '
                    : '2xl:text-[16px] xl:text-[13px] lg:text-[11px]'
                }
              >
                {item.name}
              </NavLink>
            </div>
          ))}
        </div>
        <div className="header-proposal-link flex items-center justify-center">
          <GetaProposalBtn />
        </div>
      </nav>

      {/* Mobile Navbar */}
      <nav className="w-[100%] lg:hidden flex justify-between items-center header-mobile">
        <div className="header-logo">
          <Link to="/" onClick={onLogoClick}>
            <LazyImage className="w-[94px]" src={logo} alt="logo" />
          </Link>
        </div>
        <div>
          <span className="cursor-pointer" onClick={toggleMenu}>
            <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="20" y1="0.5" x2="0" y2="0.5" stroke="white" />
              <line x1="15" y1="6.5" x2="4" y2="6.5" stroke="white" />
              <line x1="20" y1="12.5" x2="0" y2="12.5" stroke="white" />
            </svg>
          </span>
        </div>
      </nav>

      {/* Mobile Menu */}
      <nav className={isOpen ? 'mobile-menu open-menu' : 'mobile-menu close-menu'}>
        <div className="mobile-menu-top pt-3 flex justify-between items-center  ml-4 z-[20000]">
          <NavLink to="/" onClick={toggleMenu}>
            <LazyImage
              sx={{
                position: 'relative',
                zIndex: '100000',
                width: { md: '166px', xs: '92px' },
                height: { md: '43px', xs: '30px' },
              }}
              src={logo}
              alt="logo"
            />
          </NavLink>
          <span className="mobile-menu-close-button cursor-pointer mr-4" onClick={toggleMenu}>
            <svg width="20" height="19" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="3" y1="30.8513" x2="31.85" y2="2.0013" stroke="white" strokeWidth="4" strokeLinecap="round" />
              <line x1="2.82843" y1="2" x2="31.6784" y2="30.85" stroke="white" strokeWidth="4" strokeLinecap="round" />
            </svg>
          </span>
        </div>
        <div className="mobile-menu-content mt-5 flex flex-col justify-center items-start px-4 pt-10">
          {navItems.map((item) => (
            <div className=" " key={item.path}>
              <NavLink
                to={item.path}
                className={({ isActive }) =>
                  isActive ? 'isActiveNav text-[20px]' : 'text-[20px] font-bold ff-bold'
                }
                onClick={toggleMenu}
              >
                {item.name}
              </NavLink>
              
            </div>
          ))}
          
        </div>
        <div className='w-full flex flex-col items-center mt-10'>
        <GetaProposalBtn />
          </div>
      </nav>
    </header>
    </div>
  );
}

export default ResponsiveAppBar;
